import React, { useEffect, useRef, useState } from 'react';
import logo from "../../assets/logoAppka.png";
import text from "../../assets/logoNadpisy.png";
import { VscCommentDiscussion, VscAccount } from "react-icons/vsc";
import { FiMoon, FiSun } from "react-icons/fi";
import { IoSearchOutline } from "react-icons/io5";
import { CgMenuGridO } from "react-icons/cg";
import { AiOutlineInfo } from "react-icons/ai";
import { useQuery } from "@tanstack/react-query";
import {  useLocation } from 'react-router';
import UserNavbarDialog from '../dialog/UserNavbarDialog/UserNavbarDialog';
import InfoNavbarDialog from '../dialog/InfoNavbarDialog/InfoNavbarDialog';
import MessengerNavbarDialog from '../dialog/MessengerNavbarDialog/MessengerNavbarDialog';

const Navbar = () => {
  const location = useLocation();
  const [isDark, setIsDark] = useState(false);
  const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const { data: notifications } = useQuery({ queryKey: ["notifications"] });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // ✅ Create refs to track Navbar & Dialog
  const navbarRef = useRef(null);
  const dialogRef = useRef(null);
  
  // ✅ Info dialog
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  const infoRef = useRef(null);
  const toggleInfoDialog = () => setIsInfoDialogOpen(!isInfoDialogOpen);
  
  // ✅ Messenger dialog
  const [isMessengerDialogOpen, setIsMessengerDialogOpen] = useState(false);
  const messengerRef = useRef(null);
  const toggleMessengerDialog = () => setIsMessengerDialogOpen(!isMessengerDialogOpen);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isInfoDialogOpen && infoRef.current && !infoRef.current.contains(event.target)) {
        setIsInfoDialogOpen(false);
      }
  
      if (isMessengerDialogOpen && messengerRef.current && !messengerRef.current.contains(event.target)) {
        setIsMessengerDialogOpen(false);
      }
  
      if (
        isDialogOpen &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target) &&
        dialogRef.current &&
        !dialogRef.current.contains(event.target)
      ) {
        setIsDialogOpen(false);
      }
    };
  
    const handleScroll = () => {
      if (isDialogOpen) {
        setIsDialogOpen(false);
      }
    };
  
    // ✅ Zatvoriť UserNavbarDialog pri otvorení Messenger alebo Info
    if (isMessengerDialogOpen || isInfoDialogOpen) {
      setIsDialogOpen(false);
    }
  
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("wheel", handleScroll);
    window.addEventListener("touchmove", handleScroll);
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
    };
  }, [isInfoDialogOpen, isMessengerDialogOpen, isDialogOpen]);
  

  useEffect(() => {
    const selectedTheme = localStorage.getItem("theme");

    if (selectedTheme) {
      document.body.classList.add(selectedTheme);
      setIsDark(selectedTheme === 'dark');
    } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      document.body.classList.add("dark");
      setIsDark(true);
    } else {
      document.body.classList.add("light");
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = isDark ? 'light' : 'dark';
    setIsDark(!isDark);
    document.documentElement.classList.toggle('dark');
    document.body.classList.remove(isDark ? 'dark' : 'light');
    document.body.classList.add(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);

  return (
    <nav ref={navbarRef} className={`${!authUser ? "relative" : "fixed top-0 z-50"} flex justify-between items-center w-full p-2 lg:p-4 bg-background text-white border-b-2 border-mainColor-500 shadow-lg`}>
      {/* Left Section */}
      <div className="flex items-center">
        <a className="flex items-center space-x-2" href="/">
          <img src={logo} alt="logo" className='h-12' />
          <img src={text} alt="text logo" className='h-12 hidden md:block' />
        </a>

        {/* Search Bar */}
        {!location.pathname.startsWith("/profile/") && (
          <div className="flex items-center ml-4">
            <div className='relative justify-center items-center border border-mainColor-500 rounded-full p-2 shadow-custom cursor-pointer lg:hidden'>
              <IoSearchOutline className="text-2xl text-mainColor-500" />
            </div>
            <div className="hidden lg:flex items-center">
              <input
                type="text"
                placeholder="Hľadať na TAPROFI..."
                className="ml-2 p-2 rounded-l-full border border-mainColor-500 bg-transparent text-white placeholder-gray-400 focus:outline-none"
              />
              <button className="px-6 py-2 bg-mainColor-500 border border-mainColor-500 rounded-r-full">Hľadať</button>
            </div>
          </div>
        )}
      </div>

      {/* Center Section */}
      <ul className="hidden md:flex space-x-6">
        <li><a href="/offers" className="text-orange-400">Ponuky</a></li>
        <li><a href="/" className="text-orange-400">Dopyty</a></li>
        <li><a href="/" className="text-orange-400">Školenia</a></li>
      </ul>

      {/* Right Section */}
      <div className="flex items-center space-x-2 lg:space-x-4">
        {/* Theme Toggle */}
        <div onClick={toggleTheme} className="relative justify-center items-center border border-mainColor-500 rounded-full p-2 shadow-custom cursor-pointer">
          {isDark ? <FiSun className="text-2xl text-mainColor-500 " /> : <FiMoon className="text-2xl text-mainColor-500 " />}
        </div>

        {/* Menu Icon */}
        <div className='relative justify-center items-center border border-mainColor-500 rounded-full p-2 shadow-custom cursor-pointer lg:hidden'>
          <CgMenuGridO className="text-2xl cursor-pointer text-mainColor-500" />
        </div>

        {/* Messenger Icon */}
        <div
          ref={messengerRef}
          className="relative justify-center items-center border border-mainColor-500 rounded-full p-2 shadow-custom cursor-pointer"
          onClick={toggleMessengerDialog}
        >
          <VscCommentDiscussion className="text-2xl text-mainColor-500" />
          <span className="absolute -top-2 -right-2 bg-red-500 text-xs px-2 py-1 rounded-full">3</span>

          {/* Messenger Modal (Dropdown) */}
          {isMessengerDialogOpen && <MessengerNavbarDialog toggleMessengerDialog={toggleMessengerDialog} />}
        </div>


        {/* Info Icon */}
       <div
        ref={infoRef}
        className="relative justify-center items-center border border-mainColor-500 rounded-full p-2 shadow-custom cursor-pointer"
        onClick={toggleInfoDialog}
      >
        <AiOutlineInfo className="text-2xl text-mainColor-500" />
        <span className="absolute -top-2 -right-2 bg-red-500 text-xs px-2 py-1 rounded-full">
    {Array.isArray(notifications) ? notifications.length : 0}
  </span>

        {/* Info Modal (Dropdown) */}
        {isInfoDialogOpen && <InfoNavbarDialog toggleInfoDialog={toggleInfoDialog} />}
      </div>

        {/* User Avatar */}
        <div onClick={toggleDialog} className='relative justify-center items-center border border-mainColor-500 rounded-full p-2 shadow-custom cursor-pointer'>
          {authUser?.profileImg ? (
            <img
              src={authUser.profileImg}
              alt="User Avatar"
              className="h-6 w-6 lg:h-8 lg:w-8 rounded-full border-2 border-mainColor-500"
            />
          ) : (
            <VscAccount className="text-2xl text-mainColor-500 " />
          )}
        </div>

        {/* ✅ Close UserNavbarDialog when clicking anywhere else or scrolling */}
        {isDialogOpen && (
          <div ref={dialogRef}>
            <UserNavbarDialog toggleDialog={toggleDialog} />
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
