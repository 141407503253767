import Button from "../../inputs/Button";

const StepperControl = (props) => {
    return (
     <div className="flex items-center gap-2 mt-4">
      {props.hasPrevious && <Button label="Späť" outline type="button" onClick={props.onBackClick}/>}
      <Button type="submit" label='Ďalej'/>
     </div>   
    );
  };
  
  export default StepperControl;
