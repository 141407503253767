import React from "react";
import { FaStar } from "react-icons/fa";
import { CiBookmark } from "react-icons/ci";

const OfferCard = ({ 
  image, 
  time, 
  profileImage, 
  companyName, 
  categories, 
  title, 
  description, 
  duration, 
  rating 
}) => {
  return (
    <div className="bg-white rounded-2xl shadow-md overflow-hidden w-80 mx-auto">
      {/* Image Section */}
      <div className="relative">
        <img src={image} alt="Offer" className="w-full h-40 object-cover rounded-t-2xl" />
      </div>

      {/* Content Section */}
      <div className="p-4 relative text-center">
        {/* Profile Image */}
        <div className="absolute left-1/2 -top-6 transform -translate-x-1/2">
          <img src={profileImage} alt="Profile" className="w-12 h-12 rounded-full border-2 border-white shadow-lg" />
        </div>

        {/* Company Info */}
        <div className="mt-6">
          <h3 className="text-lg font-semibold">{companyName}</h3>
          <p className="text-gray-500 text-sm">{categories}</p>
        </div>

        {/* Offer Title */}
        <h2 className="text-lg font-bold mt-2">{title}</h2>
        <p className="text-gray-500 text-sm">{description}</p>

        {/* Duration */}
        <p className="text-gray-400 text-sm mt-2">{duration}</p>

        {/* Rating & Bookmark */}
        <div className="flex justify-between items-center mt-3">
          {/* Rating Stars */}
          <div className="flex items-center">
            {[...Array(5)].map((_, i) => (
              <FaStar key={i} className={`w-4 h-4 ${i < rating ? "text-yellow-400" : "text-gray-300"}`} />
            ))}
          </div>

          {/* Bookmark Icon */}
          <CiBookmark className="w-5 h-5 text-gray-500 cursor-pointer" />
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
