import React, { useRef, useState } from "react";
import Button from "../inputs/Button";
import { CiImageOn } from "react-icons/ci";

const FileInput = ({ onImagesSelected, onImageSelected, label, multiple = false, ...props }) => {
  const inputRef = useRef();
  const [previews, setPreviews] = useState([]); // Store image previews
  const [dragging, setDragging] = useState(false);

  // Handle File Selection
  const handleOnChange = (event) => {
    const files = Array.from(event.target.files);
    processFiles(files);
  };

  // Handle Drag Over
  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  // Handle Drag Leave
  const handleDragLeave = () => {
    setDragging(false);
  };

  // Handle Drop
  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const files = Array.from(event.dataTransfer.files);
    processFiles(files);
  };

  // Process File Selection (both drag & normal)
  const processFiles = (files) => {
    if (multiple && onImagesSelected) {
      const readers = files.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      Promise.all(readers)
        .then((base64Images) => {
          setPreviews(base64Images); // Show image previews
          onImagesSelected(base64Images); // Send images to parent
        })
        .catch((error) => console.error("Error reading files:", error));
    } else if (!multiple && onImageSelected && files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviews([reader.result]); // Show image preview
        onImageSelected(reader.result); // Send single image to parent
      };
      reader.readAsDataURL(files[0]);
    }
  };

  // Trigger File Picker
  const onChooseImg = () => {
    inputRef.current.click();
  };

  return (
    <div
      className={`flex flex-col items-center justify-center border-2 border-dashed rounded-lg p-6 w-80 h-64 gap-4 ${
        dragging ? "border-mainColor-500 bg-mainColor-100" : "border-gray-300"
      }`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={onChooseImg}
    >
      <CiImageOn className="w-40 h-40"/>
      {/* Image Previews */}
      {previews.length > 0 ? (
        <div className="flex flex-wrap justify-center gap-4">
          {previews.map((src, index) => (
            <img key={index} src={src} alt={`Preview ${index}`} className="w-20 h-20 object-cover rounded-lg" />
          ))}
        </div>
      ) : (
        <p className="text-gray-500 text-center ">Tu vyber alebo pretiahni fotku</p>
      )}

      {/* Hidden File Input */}
      <input
        {...props}
        className="hidden"
        type="file"
        accept="image/png, image/jpeg, image/webp"
        ref={inputRef}
        onChange={handleOnChange}
        multiple={multiple}
      />

      {/* Upload Button */}
      <Button label={label} onClick={onChooseImg} className="mt-4" />
    </div>
  );
};

export default FileInput;
