import React, { useState, useRef, useEffect } from "react";
import MultiStepForm, {
  FormStep,
} from "../../stepper/components/MultiStepForm";
import Input from "../../inputs/Input";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import ApiRequest from "../../../lib/ApiRequest"; // Ensure only one import
import {
  validationSchema,
  validationSchemaIsSuplierStepTwo,
  validationSchemaIsNoSuplierStepTwo,
} from "./components/validation";
import { Field } from "formik";
import RadioInput from "../../inputs/RadioInput";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast"; // Import toast for notification
import autoformAPI from "../../../lib/autoformApi";

const Register = ({ closeDialog }) => {
  const [supplier, setSupplier] = useState(true);
  const [visible, setVisible] = useState(false);
  const [msg, setMsg] = useState();
  const [errorMsg, setErrorMsg] = useState(null);
  const [icoSearch, setIcoSearch] = useState("");
const [businessName, setBusinessName] = useState("");

const [resultsIco, setResultsIco] = useState([]);
const [resultsName, setResultsName] = useState([]);

const [showDropdownIco, setShowDropdownIco] = useState(false);
const [showDropdownName, setShowDropdownName] = useState(false);

const [hasPromoCode, setHasPromoCode] = useState(false);

  
const detectCountryFromPhone = (phoneNumber) => {
  if (phoneNumber.startsWith("+420") || phoneNumber.startsWith("00420") || phoneNumber.startsWith("420")) {
    return "CZ";
  } else if (
    phoneNumber.startsWith("+421") ||
    phoneNumber.startsWith("00421") ||
    phoneNumber.startsWith("421") ||
    phoneNumber.startsWith("09")
  ) {
    return "SK";
  } else {
    return "UNKNOWN";
  }
};

const handleGoPayPayment = () => {
  // Sem príde reálna integrácia s GoPay
  // Zatiaľ len fake redirect
  console.log("Redirecting to GoPay...");

  // napr. redirect na backend endpoint kde vygeneruješ session
  window.location.href = "/api/gopay/start-payment";
};

  const [initialValues] = useState({
    isSupplier: "true",
    email: "",
    phoneNumber: "",
    password: "",
    re_password: "",
    firstName: "",
    lastName: "",
    companyName: "",
    businessArea: "",
    ico: "",
    dic: "",
    icdph: "",
    formatedAdress: "",
    city: "",
    country:"",
    postalCode: "",
    promoCode: "",
  });

  const supplierSectionRef = useRef(null);

  useEffect(() => {
    if (supplierSectionRef.current) {
      supplierSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [supplier]);

  const handleClick = (isSupplier) => {
    setSupplier(isSupplier);
  };

  const toggleVisibility = () => setVisible(!visible);

  const { mutate: registerMutation } = useMutation({
    mutationFn: async (values) => {
      try {
        const isSupplier = values.isSupplier === "true";

        const payload = {
          isSupplier,
          email: values.email.toLowerCase(),
          phoneNumber: values.phoneNumber,
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
        };

        if (isSupplier) {
          payload.companyData = {
            company: values.companyName,
            ico: values.ico,
            dic: values.dic,
            icdph: values.icdph,
            formatedAdress: values.formatedAdress,
            street:values.street,
            streetNumber:values.streetNumber,
            city: values.city,
            country: values.country,
            postalCode: values.postalCode,
          };
        }

        const res = await ApiRequest.post("/auth/register", payload);

        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Failed to Register.");
        }

        return res.data;
      } catch (error) {
        console.error("Error during register mutation:", error?.response?.data || error.message || error);
        throw error;
      }
    },
    onSuccess: (data) => {
      toast.success(data.message || "Registrácia prebehla úspešne!");
      setMsg(data.message);
      closeDialog();
    },
    onError: (error) => {
      const message = error?.response?.data?.message || "Niečo sa pokazilo!";
      setErrorMsg(message);
      toast.error(message);
    },
  });

  const handleIcoInputChange = async (e) => {
    const value = e.target.value;
    setIcoSearch(value);
    setShowDropdownName(false); // Zavri názov firmy dropdown
  
    if (value.length > 1) {
      try {
        const response = await autoformAPI.get("/search", {
          params: {
            q: `cin:${value}`,
            private_access_token: "f92c4467eadf9300b9acf307f332d7d4d6b7fbca5add7eb2b96e57e3e4391210a784b65d07d9e3ff",
            limit: 10,
          },
        });
  
        setResultsIco(response.data);
        setShowDropdownIco(true);
      } catch {
        setResultsIco([]);
        setShowDropdownIco(false);
      }
    } else {
      setResultsIco([]);
      setShowDropdownIco(false);
    }
  };

  const handleInputChange = async (e) => {
    const value = e.target.value;
    setBusinessName(value);
    setShowDropdownIco(false); // Zavri IČO dropdown
  
    if (value.length > 1) {
      try {
        const response = await autoformAPI.get("/search", {
          params: {
            q: `name:${value}`,
            private_access_token: "f92c4467eadf9300b9acf307f332d7d4d6b7fbca5add7eb2b96e57e3e4391210a784b65d07d9e3ff",
            limit: 20,
          },
        });
  
        setResultsName(response.data);
        setShowDropdownName(true);
      } catch {
        setResultsName([]);
        setShowDropdownName(false);
      }
    } else {
      setResultsName([]);
      setShowDropdownName(false);
    }
  };
  const handleSubmit = async (values) => {
    try {
      console.log("Submitting:", values);
      registerMutation(values);
    } catch (err) {
      console.error("Submit failed:", err);
    }
  };

  const handleResultSelect = (result, setFieldValue) => {
    setBusinessName(result.name);
    setIcoSearch(result.cin || "");

    // Use setFieldValue to update the Formik fields
    setFieldValue("companyName", result.name || "")
    setFieldValue("ico", result.cin || "")
    setFieldValue("dic", result.tin || "")
    setFieldValue("icdph", result.vatin || "")
    setFieldValue("formatedAdress", result.formatted_street || "")
    setFieldValue("street", result.street || "")
    setFieldValue("streetNumber", result.street_number || "")
    setFieldValue("city", result.municipality || "");
    setFieldValue("country", result.country || "");   
    setFieldValue("postalCode", result.postal_code || "");
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <FormStep
            stepName="Prihlasovacie údaje"
            onSubmit={() => console.log("Step1 submit")}
            validationSchema={validationSchema}
          >
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-2 mt-4">
                <p className="font-semibold" name="isSupplier">Chcem:</p>
                <Field name="isSupplier">
                  {({ field }) => (
                    <div className="flex flex-row items-center gap-2 mt-4">
                      <RadioInput
                        {...field}
                        type="radio"
                        label="Ponúkať služby"
                        value="true"
                        checked={field.value === "true"}
                        onClick={() => handleClick(true)}
                      />
                      <RadioInput
                        {...field}
                        type="radio"
                        label="Hľadať odborníkov"
                        value="false"
                        checked={field.value === "false"}
                        onClick={() => handleClick(false)}
                      />
                    </div>
                  )}
                </Field>
              </div>

              <Input name="email" label="Email" />
              <Input name="phoneNumber" label="Telefónne číslo" />
              <Input
                name="password"
                label="Heslo"
                type={visible ? "text" : "password"}
                rightIcon={visible ? MdVisibility : MdVisibilityOff}
                onIconClick={toggleVisibility}
              />
              <Input
                name="re_password"
                label="Zopakuj heslo"
                type={visible ? "text" : "password"}
                rightIcon={visible ? MdVisibility : MdVisibilityOff}
                onIconClick={toggleVisibility}
              />
            </div>
          </FormStep>
        );
      case 1:
        return supplier ? (
          <FormStep
          stepName="Fakturačné údaje"
          onSubmit={() => console.log("Step2 submit")}
          validationSchema={validationSchemaIsSuplierStepTwo}
        >
          {({ setFieldValue }) => (
            <div className="flex flex-col gap-4" ref={supplierSectionRef}>
              <Input
  name="ico"
  label="IČO"
  value={icoSearch}
  onChange={handleIcoInputChange}
/>

{showDropdownIco && (
  <div className="relative w-full bg-background border border-mainColor-500 rounded-md z-50">
    {resultsIco.length > 0 ? (
      resultsIco.map((result, index) => (
        <div
          key={`ico-${index}`}
          className="px-4 py-2 cursor-pointer hover:bg-gray-100"
          onClick={() => handleResultSelect(result, setFieldValue)}
        >
          <strong>{result.name}</strong>
          <p className="text-sm text-gray-500">{result.formatted_address}</p>
        </div>
      ))
    ) : (
      <div className="p-4 text-gray-500">Nenašli sa žiadne výsledky.</div>
    )}
  </div>
)}

<Input
  name="companyName"
  label="Názov firmy"
  value={businessName}
  onChange={handleInputChange}
/>

{showDropdownName && (
  <div className="relative w-full bg-background border border-mainColor-500 rounded-md z-50">
    {resultsName.length > 0 ? (
      resultsName.map((result, index) => (
        <div
          key={`name-${index}`}
          className="px-4 py-2 cursor-pointer hover:bg-gray-100"
          onClick={() => handleResultSelect(result, setFieldValue)}
        >
          <strong>{result.name}</strong>
          <p className="text-sm text-gray-500">{result.formatted_address}</p>
        </div>
      ))
    ) : (
      <div className="p-4 text-gray-500">Nenašli sa žiadne výsledky.</div>
    )}
  </div>
)}
              <Input name="firstName" label="Meno" />
              <Input name="lastName" label="Priezvisko" />
              <Input name="dic" label="DIČ" />
              <Input name="icdph" label="IČ DPH" />
              <Input name="formatedAdress" label="Adresa" />
              <Input name="city" label="Mesto" />
              <Input name="postalCode" label="PSČ" />
              <Input name="country" label="Krajina" />
            </div>
          )}
        </FormStep>
        
        ) : (
          <FormStep
            stepName="Osobné údaje"
            onSubmit={() => console.log("Step2 submit")}
            validationSchema={validationSchemaIsNoSuplierStepTwo}
          >
            <div className="flex flex-col gap-4">
              <Input name="firstName" label="Meno" />
              <Input name="lastName" label="Priezvisko" />
            </div>
          </FormStep>
        );
     case 2:
  return supplier ? (
    <FormStep
      stepName="Platobné údaje"
      onSubmit={() => console.log("Step3 submit")}
      validationSchema={validationSchema}
    >
      <div className="flex flex-col gap-6">
        {/* 🔘 Zľavový kód sekcia */}
        <div className="bg-mainColor-50 p-4 rounded-xl shadow-sm border border-mainColor-100">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="promoToggle"
              checked={hasPromoCode}
              onChange={() => setHasPromoCode(!hasPromoCode)}
              className="accent-mainColor-500 w-5 h-5"
            />
            <label htmlFor="promoToggle" className="font-medium text-mainColor-800">
              Máš zľavový kód?
            </label>
          </div>

          {hasPromoCode ? (
            <div className="mt-3">
              <Input name="promoCode" label="Zľavový kód" />
            </div>
          ) : (
            <p className="text-sm text-gray-500 mt-2">
              Nemáš zľavový kód? Pokračuj k platbe.
            </p>
          )}
        </div>

        {/* 💳 GoPay box */}
        <div className="bg-white p-5 rounded-xl shadow-md border border-gray-100">
          <h4 className="text-lg font-semibold text-gray-800 mb-4 flex items-center gap-2">
            <img src="/gopay-logo.svg" alt="GoPay" className="h-6" />
            Platba cez GoPay
          </h4>

          <button
            type="button"
            onClick={handleGoPayPayment}
            className="bg-mainColor-500 hover:bg-mainColor-600 transition text-white font-semibold py-2 px-6 rounded-lg w-full sm:w-auto"
          >
            Zaplatiť s GoPay
          </button>
        </div>
      </div>
    </FormStep>

        ) : null;
      default:
        return "Neznámy krok";
    }
  };

  return (
    <MultiStepForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      error={errorMsg}
      msg={msg}
      handleClick={handleClick}
      supplier={supplier}
    >
      {getStepContent(0)}
      {getStepContent(1)}
      {getStepContent(2)}
    </MultiStepForm>
  );
};

export default Register;
