import { useQuery } from "@tanstack/react-query";
import apiRequest from "../lib/ApiRequest"; // Ensure apiRequest is correctly set up

// Function to fetch countries from the backend
const fetchCountries = async () => {
  try {
    const response = await apiRequest.get(`/location/countries`);
    return response.data; // Ensure backend returns an array of countries
  } catch (error) {
    console.error("Error fetching countries:", error.response?.data || error.message);
    throw new Error("Failed to load countries");
  }
};

// Custom React Query hook to fetch countries
export function useCountries() {
  return useQuery({
    queryKey: ["countries"],
    queryFn: fetchCountries,
    staleTime: 1000 * 60 * 10, // Cache data for 10 minutes
    onError: (error) => {
      console.error("React Query Error - Countries:", error.message);
    },
  });
}
