import { useState, useRef } from "react";
import { GoPencil } from "react-icons/go";
import { useQuery } from "@tanstack/react-query";
import { VscAccount } from "react-icons/vsc";
import ProfileImageDialog from "../../../components/dialog/userProfileDialog/ProfileImageDialog";

const ProfileImage = () => {
  const { data: user } = useQuery({ queryKey: ["user"] });
  const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null); // Ref for file input

  const IsMyProfile = authUser?._id === user?._id;

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file); // Store selected file
      setModalOpen(true); // Open cropping modal
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="relative mx-3 top-[-50px]">
        {authUser?.profileImg ? (
          <img
            src={user?.profileImg}
            alt="Profile"
            className="w-[150px] h-[150px] rounded-full border-2 border-mainColor-500"
          />
        ) : (
          <VscAccount className="w-[150px] h-[150px] text-mainColor-500 rounded-full border-2 border-mainColor-500" />
        )}

        {IsMyProfile && (
          <>
            <button
              className="absolute -bottom-6 left-0 right-0 m-auto w-fit p-[1rem] rounded-full bg-background border-2 border-mainColor-500 text-mainColor-500"
              title="Change photo"
              onClick={() => fileInputRef.current.click()} // Open file picker
            >
              <GoPencil />
            </button>

            {/* Hidden file input */}
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              accept="image/*"
              onChange={handleFileSelect} // Handle file selection
            />
          </>
        )}
      </div>

      {modalOpen && (
        <ProfileImageDialog 
          closeModal={() => setModalOpen(false)} 
          selectedFile={selectedFile} // Pass the selected file to modal
        />
      )}
    </div>
  );
};

export default ProfileImage;
