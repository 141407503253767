import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import apiRequest from "../lib/ApiRequest";

const useCommentOnPost = () => {
    const queryClient = useQueryClient();

    const { mutate: commentPost, isLoading: isCommenting } = useMutation({
        mutationFn: async ({ postId, text }) => {
            const res = await apiRequest.post(`/posts/comment/${postId}`, { text });
            return res.data; // API should return updated post with comments
        },
        onSuccess: (updatedPost, variables) => {
        const {postId,userId}=variables

            // ✅ Update "posts" list (if needed)
            queryClient.setQueryData(["posts"], (oldData) => {
                if (!oldData) return [];
                return oldData.map((p) =>
                  p._id === postId ? { ...p, comments: updatedPost.comments } : p
                );
              });
console.log(userId)
            // ✅ Invalidate "userPost" query to refresh the detailed post view
            if (queryClient.getQueryData(["userPost", userId])) {
                queryClient.invalidateQueries(["userPost", userId]); // ✅ Invalidate with userId
            }

            toast.success("Comment added successfully!");
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Failed to add comment.");
        },
    });

    return { commentPost, isCommenting };
};

export default useCommentOnPost;
