import React, { useState } from "react";
import { FaRegComment } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { formatRelativeTime } from "../../../utils/timeFormatter";
import LoadingSpinner from "../../LoadingSpinner";
import { FaHeart, FaRegHeart } from "react-icons/fa6";
import { useQuery } from "@tanstack/react-query";
import useLikeUnlike from "../../../hooks/useLikeUnlikePost";
import CommentList from "./CommentList";
import useCommentOnPost from "../../../hooks/useCommentPost";

const SingelPostComment = ({ post, displayName, onClose }) => {
    const { data: authUser } = useQuery({ queryKey: ["authUser"] });
    const { likeUnlikePost, isLiking } = useLikeUnlike();
    const [commentText, setCommentText] = useState("");
    const isLiked = post?.likes?.includes(authUser?._id);
    const { commentPost, isCommenting } = useCommentOnPost();

    const handlePostComment = (e) => {
        e.preventDefault();
        if (!commentText.trim()) return;

        commentPost({ postId: post._id, text: commentText });
        setCommentText(""); // Reset input after posting
    };

    return (
        <div className="fixed inset-0 bg-mainColor-800/70 bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-background rounded-lg w-full max-w-lg shadow-lg max-h-[90vh] overflow-y-auto">
                {/* Header */}
                <div className="sticky top-0 bg-background z-10 flex justify-between items-center p-4 border-b border-mainColor-500">
                    <h2 className="text-lg text-primaryText font-semibold truncate">
                        Príspevok používateľa {displayName}
                    </h2>
                    <IoMdClose
                        className="text-2xl cursor-pointer text-mainColor-500"
                        onClick={onClose}
                    />
                </div>

                {/* User Info */}
                <div className="p-4 hidden sm:flex items-center gap-3">
                    <img
                        src={post.user.profileImg}
                        alt="User"
                        className="w-10 h-10 rounded-full"
                    />
                    <div>
                        <p className="font-semibold">{displayName}</p>
                        <p className="text-gray-500 text-sm">{formatRelativeTime(post?.createdAt)}</p>
                    </div>
                </div>

                {/* Post Content */}
                <div className="px-4 hidden sm:block">
                    <p className="text-primaryText break-words">{post.text}</p>
                </div>

                {/* Image Section */}
                {post.img && (
                    <div className="mt-4 hidden sm:block">
                        <img src={post.img} alt="Post" className="w-full object-cover" />
                    </div>
                )}

                {/* Engagement Buttons */}
                <div className="p-4 border-y border-mainColor-500 flex justify-between text-primaryText text-lg">
                    <div 
                        className="flex gap-2 items-center group cursor-pointer text-mainColor-500"
                        onClick={() => likeUnlikePost(post._id)}
                    >
                        {isLiking && <LoadingSpinner size={20} color="#fb6c09" />}
                        {!isLiked && !isLiking && <FaRegHeart className="text-xl cursor-pointer hover:text-red-500" />}
                        {isLiked && !isLiking && <FaHeart className="text-xl cursor-pointer hover:text-red-500" />}
                        <span className="text-primaryText hover:text-mainColor-500">
                            {post?.likes?.length || 0} Likes
                        </span>
                    </div>
                    <div className="flex items-center gap-2 cursor-pointer text-mainColor-500">
                        <FaRegComment />
                        <span className="text-primaryText hover:text-mainColor-500">
                            {post.comments.length} Komentárov
                        </span>
                    </div>
                </div>

                {/* Comments Section */}
                <div className="max-h-[60vh] overflow-y-auto p-4">
                    <CommentList comments={post.comments} />
                </div>

                {/* Comment Input */}
                <div className="p-4 flex items-center gap-3 border-t border-mainColor-500">
                    <form onSubmit={handlePostComment} className="w-full flex gap-2 items-center">
                        <img
                            src={authUser.profileImg}
                            alt="User"
                            className="w-10 h-10 rounded-full"
                        />
                        <input
                            type="text"
                            placeholder="Pridať komentár..."
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                            className="w-full p-2 rounded-full border border-mainColor bg-transparent text-primaryText placeholder-gray-500 focus:outline-none"
                        />
                        <button
                            type="submit"
                            disabled={isCommenting}
                            className="bg-mainColor-500 rounded-full btn-sm text-white px-4">
                            Uverejniť
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SingelPostComment;
