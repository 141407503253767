import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { FiCheck, FiX } from "react-icons/fi";

const ImageCropper = ({ image, onCropDone, closeModal }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const aspectRatio = 1 / 1; // Square aspect ratio

  const onCropComplete = (_, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const getCroppedImg = useCallback(async () => {
    if (!croppedArea) return;

    const imageObj = new Image();
    imageObj.src = image;

    return new Promise((resolve) => {
      imageObj.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = croppedArea.width;
        canvas.height = croppedArea.height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          imageObj,
          croppedArea.x,
          croppedArea.y,
          croppedArea.width,
          croppedArea.height,
          0,
          0,
          croppedArea.width,
          croppedArea.height
        );

        resolve(canvas.toDataURL("image/jpeg")); // Convert to Base64
      };
    });
  }, [croppedArea, image]);

  const handleSave = async () => {
    const croppedImage = await getCroppedImg();
    if (croppedImage) {
      onCropDone(croppedImage); // Pass the processed image to parent for saving
    }
  };

  return (
    <div className="flex flex-col items-center">
      {/* Cropper Container */}
      <div className="relative w-96 h-96 rounded-full border- overflow-hidden flex items-center justify-center bg-black">
        {/* <div> */}
        <Cropper
          image={image}
          aspect={aspectRatio}
          crop={crop}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          showGrid={false}
          cropShape={"round"}
          objectFit="contain"
          style={{
            containerStyle: {
              height: "100%",
              backgroundColor: "#fff",
            },
            cropAreaStyle: {
              border: "4px solid #fb6c09",
              width: "100%",
              height: "100%",
              overflow: "hidden"
            },
            // mediaStyle: {
            //   height: "100%",
            //   display: "block",
            // },
          }}
        />

      </div>

      {/* Buttons */}
      <div className="flex items-center justify-center gap-4 mt-6">
        <button
          className="px-5 py-2 bg-green-500 text-white rounded-lg flex items-center gap-2 hover:bg-green-600 transition duration-300"
          onClick={handleSave}
        >
          <FiCheck size={18} /> Uložiť
        </button>

        <button
          className="px-5 py-2 bg-red-500 text-white rounded-lg flex items-center gap-2 hover:bg-red-600 transition duration-300"
          onClick={closeModal}
        >
          <FiX size={18} /> Zrušiť
        </button>
      </div>
    </div>
  );
};

export default ImageCropper;
