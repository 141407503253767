import React, { useState } from "react";
import FileInput from "../../components/inputs/FileInput";
import Button from "../../components/inputs/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiRequest from "../../lib/ApiRequest";
import toast from "react-hot-toast";
import PostImageCropper from "./components/PostImageCropper";
import { FaArrowLeftLong } from "react-icons/fa6";
import { VscAccount } from "react-icons/vsc";

const AddPost = ({ closeModal, user }) => {
    const [postImg, setPostImg] = useState("");
    const [currentPage, setCurrentPage] = useState("choose-img");
    const [profileImgAfterCrop, setProfileImgAfterCrop] = useState("");
    const [postText, setPostText] = useState("");
    const [croppedArea, setCroppedArea] = useState(null);
    const queryClient = useQueryClient();

    const onImageSelected = (selectedImg) => {
        setPostImg(selectedImg);
        setCurrentPage("crop-img");
    };

    const onCropDone = (croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    };

    const onCropSave = () => {
        if (!postImg || !croppedArea) return;

        const canvasEle = document.createElement("canvas");
        const context = canvasEle.getContext("2d");
        let imageObj1 = new Image();

        imageObj1.src = postImg;
        imageObj1.onload = function () {
            canvasEle.width = croppedArea.width;
            canvasEle.height = croppedArea.height;

            context.drawImage(
                imageObj1,
                croppedArea.x,
                croppedArea.y,
                croppedArea.width,
                croppedArea.height,
                0,
                0,
                croppedArea.width,
                croppedArea.height
            );

            const dataURL = canvasEle.toDataURL("image/jpeg");
            setProfileImgAfterCrop(dataURL);
            setCurrentPage("image-cropped");
        };
    };

    const onCropCancel = () => {
        setCurrentPage("choose-img");
        setPostImg("");
    };

    const handleTextChange = (e) => {
        setPostText(e.target.value);
    };

    const { mutate: createPost } = useMutation({
        mutationFn: async ({ file, text }) => {
            const formData = new FormData();
            formData.append("postsImage", file);
            formData.append("text", text);

            const res = await apiRequest.post("/posts/create", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            return res.data;
        },
        onSuccess: (data) => {
            toast.success(data.message);
            queryClient.invalidateQueries({ queryKey: ["user"] });
            queryClient.invalidateQueries({ queryKey: ["authUser"] });
            queryClient.invalidateQueries({ queryKey: ["userPost"] });
            closeModal();
        },
        onError: (error) => {
            const message = error.response?.data?.message || "Something went wrong!";
            toast.error(message);
        },
    });

    const displayName = user?.isSupplier
        ? user.companyData?.[0].company // Use company name for suppliers
        : `${user?.firstName} ${user?.lastName}`; // Otherwise, use first and last name


    return (
        <div className="fixed inset-0 bg-mainColor-700 bg-opacity-90 flex items-center justify-center z-50">
            <div className="fixed flex h-full max-w-5xl w-full bg-black rounded-lg overflow-hidden flex-col">
                {/* Header */}
                <div className="w-full bg-black flex justify-between items-center p-2 border-b border-mainColor-500">
                    <FaArrowLeftLong
                        onClick={() => {
                            if (currentPage === "crop-img") {
                                setCurrentPage("choose-img");
                            } else {
                                closeModal();
                            }
                        }}
                        className="text-2xl cursor-pointer text-mainColor-500"
                    />
                    <div className="text-xl text-mainColor-500 flex-1 text-center">
                        {currentPage === "choose-img"
                            ? "Vyber obrazok"
                            : currentPage === "crop-img"
                                ? "Orezať"
                                : ""}
                    </div>
                    {currentPage === "crop-img" && (
                        <button
                            className="text-xl text-mainColor-500"
                            onClick={onCropSave}
                        >
                            Ďalej
                        </button>
                    )}
                </div>

                {/* Content */}
                <div className="flex items-center justify-center w-full h-full gap-4">
                    {currentPage === "choose-img" ? (
                        <div className="display flex flex-col items-center gap-4">
                            <FileInput onImageSelected={onImageSelected} label={"Vyber fotku alebo Video"} />
                        </div>
                    ) : currentPage === "crop-img" ? (
                        <PostImageCropper
                            image={postImg}
                            onCropDone={onCropDone}
                            onCropCancel={onCropCancel}
                        />
                    ) : (
                        <div className="flex flex-col md:flex-row bg-black min-h-screen items-center justify-center p-4">
                        {/* Left Side - Image */}
                        <div className="relative w-full md:w-2/3 mb-4 md:mb-0">
                          <img
                            src={profileImgAfterCrop}
                            alt="postImage"
                            className="w-full h-auto object-cover"
                          />
                        </div>
                        {/* Right Side - Sidebar */}
                        <div className="w-full md:w-1/3 bg-gray-900 text-white p-6 flex flex-col gap-4 rounded-lg">
                          <div className="flex items-center gap-4">
                            <div className="flex-shrink-0">
                              {user?.profileImg ? (
                                <img
                                  src={user.profileImg}
                                  alt="Profile"
                                  className="w-12 h-12 rounded-full border border-mainColor-500"
                                />
                              ) : (
                                <VscAccount className="w-12 h-12 text-mainColor-500 rounded-full border border-mainColor-500" />
                              )}
                            </div>
                            <div>
                              <h3 className="text-lg font-semibold">{displayName}</h3>
                            </div>
                          </div>
                          <textarea
  className="w-full bg-gray-800 p-2 rounded-md text-white"
  placeholder="Napíšte popis..."
  rows="3"
  value={postText} // Prepojíme stav
  onChange={handleTextChange} // Aktualizujeme stav pri zmene
></textarea>
                          <button
                            className="bg-mainColor-500 hover:bg-mainColor-700 px-4 py-2 rounded-md"
                            onClick={() => {
                              const byteString = atob(profileImgAfterCrop.split(",")[1]);
                              const mimeString = profileImgAfterCrop.split(",")[0].split(":")[1].split(";")[0];
                              const arrayBuffer = new ArrayBuffer(byteString.length);
                              const uint8Array = new Uint8Array(arrayBuffer);
                              for (let i = 0; i < byteString.length; i++) {
                                uint8Array[i] = byteString.charCodeAt(i);
                              }
                              const blob = new Blob([uint8Array], { type: mimeString });
                              const file = new File([blob], "profile.jpg", { type: mimeString });
                  
                              createPost({ file, text: postText });
                            }}
                          >
                            Zdieľať
                          </button>
                        </div>
                      </div>

                    )}
                </div>
            </div>
        </div>
    );
};

export default AddPost;
