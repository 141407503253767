import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import "./i18n.js";
import "./styles/calendar.css"
import DashBoard from './pages/DashBoard.js';
import NotFoundPage from './pages/NotFoundPage.js';
import Home from './pages/Home/Home.js';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ProfileLayout from './pages/ProfilePage/ProfileLayout.js';
import ProfilePage from './pages/ProfilePage/ProfilePage.js';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import AuthWrapper from './components/auth/AuthWrapper.js';
import PostDetailPage from './pages/ProfilePage/components/PostDetailPage.js';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { Suspense } from "react";
import Offers from './pages/Offers/Offers.js';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false,  // Prevent unnecessary refetches
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage, 
});


persistQueryClient({
  queryClient,
  persister,
  maxAge: 1000 * 60 * 60 * 24, // Keep cache for 24 hours
  retry: false, // ✅ Prevent infinite retries if cache is corrupted
});

// Define Routes
const router = createBrowserRouter([
  {
    path: "/",
    element: <DashBoard />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/profile/:profileId", // ✅ Move ProfileLayout inside this route
        element: <ProfileLayout />,
        children: [{ path: "", element: <ProfilePage /> }],
      },
      { path: "/reset-password/:id/:token", element: <AuthWrapper /> },
      {
        path: "/post/:postId",  // <-- New Post Detail Route
        element: <PostDetailPage />,
      },
      {
        path: "/offers",  // <-- New offe Detail Route
        element: <Offers />,
      },
      { path: "*", element: <NotFoundPage /> }, // ✅ Handle unknown paths
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<div className="h-screen flex items-center justify-center">Loading...</div>}>
        <RouterProvider router={router} />
      </Suspense>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);
