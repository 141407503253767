import React, { useEffect, useMemo, useState } from "react";
import Calendar from "react-calendar";
import { useParams } from "react-router-dom";
import { format, getDay, isAfter } from "date-fns";
import Button from "../../inputs/Button";
import apiRequest from "../../../lib/ApiRequest";
import { useQuery } from "@tanstack/react-query";

const BookingCalendar = ({ onDateSelect, onTimeSelect, selectedService }) => {
  const { profileId } = useParams();
  const today = new Date();

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");

  const [weeklyWorkingHours, setWeeklyWorkingHours] = useState({
    0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null
  });

  // 📥 Načítanie rezervácií
  const { data: reservationSettings } = useQuery({
    queryKey: ["reservationSettings", profileId],
    queryFn: async () => {
      const res = await apiRequest.get(`/reservationSettings/${profileId}`);
      return res.data || {};
    },
    enabled: !!profileId,
  });

  // 📥 Načítanie pracovných hodín
  const { data: rawWorkingHours = [] } = useQuery({
    queryKey: ["workingHours", profileId],
    queryFn: async () => {
      const res = await apiRequest.get(`appointment/workingHours/${profileId}`);
      return res.data?.workingHours || [];
    },
    enabled: !!profileId,
  });

  useEffect(() => {
    const formatted = { 0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null };
    rawWorkingHours.forEach(entry => {
      if (entry && entry.day >= 0 && entry.day <= 6) {
        formatted[entry.day] = (entry.start && entry.end) ? { start: entry.start, end: entry.end } : null;
      }
    });
    setWeeklyWorkingHours(formatted);
  }, [rawWorkingHours]);

  // 📥 Dostupné dni
  const { data: availableDays = [] } = useQuery({
    queryKey: ["availableDays", profileId, selectedService?._id],
    queryFn: async () => {
      const res = await apiRequest.get(
        `appointment/available-days/${profileId}?serviceID=${selectedService?._id}`
      );
      return res.data || [];
    },
    enabled: !!profileId && !!selectedService,
  });

  // 📥 Sloty
  const { data: availableSlots = [], isLoading: isLoadingSlots } = useQuery({
    queryKey: ["availableSlots", selectedDate, selectedService?._id],
    queryFn: async () => {
      if (!selectedDate || !selectedService?._id) return [];
      const res = await apiRequest.post("appointment/available-slots", {
        serviceID: selectedService._id,
        supId: profileId,
        date: format(selectedDate, "yyyy-MM-dd"),
        duration: selectedService.duration,
      });
      return res.data.availableSlots || [];
    },
    enabled: !!selectedDate && !!selectedService,
  });

  const availableSlotsPerDay = useMemo(() => {
    const map = {};
    availableDays.forEach(day => {
      map[day.date] = day.availableSlots; // alebo pre custom service by si tu držal aj `serviceId`
    });
    return map;
  }, [availableDays]);
  

  const isValidDate = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    const dayOfWeek = getDay(date);
  
    if (!isAfter(date, today)) return false;
    if (!weeklyWorkingHours[dayOfWeek]) return false;
  
    if (reservationSettings) {
      const minBookingTime = new Date();
      minBookingTime.setHours(minBookingTime.getHours() + reservationSettings.minHoursBeforeBooking);
      if (date < minBookingTime) return false;
  
      const maxBookingDate = new Date();
      maxBookingDate.setDate(maxBookingDate.getDate() + reservationSettings.maxDaysInAdvance);
      if (date > maxBookingDate) return false;
    }
  
    // 🧠 Namiesto kontroly počtu slotov zo `availableDays`, skontroluj, či to je selectedDate
    // a ak áno, či existujú nejaké dostupné sloty (už máš natiahnuté `availableSlots`)
    if (selectedService && selectedDate && format(selectedDate, "yyyy-MM-dd") === formattedDate) {
      return availableSlots.length > 0;
    }
  
    // Pre ostatné dni použi predpripravenú mapu – fallback
    return availableSlotsPerDay[formattedDate] > 0;
  };
  
  

  // 🟠 Klik na dátum
  const handleDateChange = (date) => {
    if (!isValidDate(date)) return;
    setSelectedDate(date);
    onDateSelect(format(date, "yyyy-MM-dd"));
  };

  // 🟠 Klik na čas
  const handleTimeChange = (time) => {
    setSelectedTime(time);
    onTimeSelect(time);
  };

  return (
    <div className="p-4 mx-auto">
      <h2 className="text-xl font-bold mb-4">Rezervácia termínu</h2>

      {!selectedService ? (
        <p className="text-gray-500 italic mb-4">
          ⏳ Prosím, vyberte najprv službu, aby sa zobrazili dostupné dátumy.
        </p>
      ) : (
        <>
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            tileDisabled={({ date }) => {
              const formattedDate = format(date, "yyyy-MM-dd");
              const dayOfWeek = getDay(date);
            
              // ❌ minulosť
              if (!isAfter(date, today)) return true;
            
              // ❌ nepracovný deň
              if (!weeklyWorkingHours[dayOfWeek]) return true;
            
              // ❌ min/max obmedzenie
              if (reservationSettings) {
                const minBookingTime = new Date();
                minBookingTime.setHours(minBookingTime.getHours() + reservationSettings.minHoursBeforeBooking);
                if (date < minBookingTime) return true;
            
                const maxBookingDate = new Date();
                maxBookingDate.setDate(maxBookingDate.getDate() + reservationSettings.maxDaysInAdvance);
                if (date > maxBookingDate) return true;
              }
            
              // ❌ ak nie je vybraná služba, všetko disable
              if (!selectedService) return true;
            
              // ❌ ak pre daný deň nie sú sloty pre konkrétnu službu
              const availableForDay = availableSlotsPerDay[formattedDate];
              return !availableForDay || availableForDay === 0;
            }}
            
            tileClassName={({ date }) => {
              const formattedDate = format(date, "yyyy-MM-dd");
              const dayAvailability = availableDays.find(day => day.date === formattedDate);

              if (dayAvailability && dayAvailability.availableSlots === 0) {
                return "fully-booked";
              }

              return selectedDate && formattedDate === format(selectedDate, "yyyy-MM-dd")
                ? "custom-selected-tile"
                : "";
            }}
          />

          {selectedDate && (
            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">
                Dostupné časy pre{" "}
                <span className="text-mainColor-500">
                  {format(selectedDate, "dd.MM.yyyy")}
                </span>:
              </h3>

              {isLoadingSlots ? (
                <p className="mt-2 text-gray-500">⏳ Načítavam sloty...</p>
              ) : availableSlots.length > 0 ? (
                <div className="grid grid-cols-3 gap-2 mt-2">
                  {availableSlots.map((slot, i) => (
                    <Button
                      key={i}
                      label={`${slot.startTime} – ${slot.endTime}`}
                      onClick={() => handleTimeChange(slot.startTime)}
                      outline={selectedTime !== slot.startTime}
                    />
                  ))}
                </div>
              ) : (
                <p className="mt-2 text-red-500">❌ Žiadne dostupné termíny pre tento deň.</p>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BookingCalendar;
