import React, { useState } from "react";
import StepperControl from "./StepperControl";
import { Form, Formik } from "formik";
import Stepper from "../Stepper";
import Button from "../../inputs/Button";
import { useFormikContext } from "formik";

const MultiStepForm = ({ children, initialValues, onSubmit, msg, error, supplier }) => {
  const [stepNumber, setStepNumber] = useState(0);
  const [snapshot, setSnapshot] = useState(initialValues);
  const [showReview, setShowReview] = useState(false);

  const steps = React.Children.toArray(children);
  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values) => {
    setSnapshot({ ...snapshot, ...values });
    setStepNumber(stepNumber + 1);
  };

  const previous = (values) => {
    setSnapshot({ ...snapshot, ...values });
    setStepNumber(stepNumber - 1);
  };

  const handleSubmit = async (values, actions) => {
    setSnapshot({ ...snapshot, ...values });

    if (step.props.onSubmit) {
      await step.props.onSubmit(values);
    }

    if (isLastStep) {
      setShowReview(true);
    } else {
      actions.setTouched({});
      next(values);
    }
  };

  return (
    <div className="">
      {showReview ? (
        <div className="review-screen">
          <h3 className="font-bold flex justify-center text-gray-800 mb-4">
            Zhrnutie informacii
          </h3>
          <div>
            <strong>Registrovaný ako: </strong>
            {supplier ? "Ponúkať služby" : "Hľadať odborníkov"}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div>
              <h4 className="font-semibold text-gray-700">Prihlasovacie údaje</h4>
              <p><strong>Email:</strong> {snapshot.email}</p>
              <p><strong>Telefonné číslo:</strong> {snapshot.phoneNumber}</p>
            </div>

            {supplier ? (
              <div>
                <h4 className="font-semibold text-gray-700">Firemné údaje</h4>
                <p><strong>Názov spoločnosti:</strong> {snapshot.companyName}</p>
                <p><strong>Oblasť podnikania:</strong> {snapshot.businessArea}</p>
                <p><strong>IČO:</strong> {snapshot.ico}</p>
                <p><strong>DIČ:</strong> {snapshot.dic}</p>
                <p><strong>IČdph:</strong> {snapshot.icdph}</p>
                <p><strong>Adresa:</strong> {snapshot.address}</p>
                <p><strong>Mesto:</strong> {snapshot.city}</p>
                <p><strong>PSČ:</strong> {snapshot.psc}</p>
                <p><strong>Meno a Priezvisko:</strong> {snapshot.firstName} {snapshot.lastName}</p>
              </div>
            ) : (
              <div>
                <h4 className="font-semibold text-gray-700">Osobné údaje</h4>
                <p><strong>Meno a Priezvisko:</strong> {snapshot.firstName} {snapshot.lastName}</p>
              </div>
            )}

            {snapshot.promoCode && (
              <div>
                <h4 className="font-semibold text-gray-700">Promo Code</h4>
                <p><strong>Promo Code:</strong> {snapshot.promoCode}</p>
              </div>
            )}
          </div>

          {/* Error and Success Messages */}
          {error && <p className="text-sm text-red-600">{error}</p>}
          {msg && <p className="text-sm text-green-600">{msg}</p>}

          <div className="flex justify-between">
            <Button
              type="button"
              outline
              onClick={() => setShowReview(false)}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg shadow hover:bg-gray-300"
              label="Späť"
            />

            <Button
              type="button" // Changed to "button" to prevent default form submission
              label="Registrovať"
              onClick={() => onSubmit(snapshot)}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700"
            />
          </div>
        </div>
      ) : (
        <Formik
          initialValues={snapshot}
          onSubmit={handleSubmit}
          validationSchema={step.props.validationSchema}
        >
          {(formik) => (
            <Form>
              <Stepper
                stepNumber={stepNumber}
                isLastStep={isLastStep}
                steps={steps}
              />

              {step}

              <StepperControl
                isLastStep={isLastStep}
                hasPrevious={stepNumber > 0}
                onBackClick={() => previous(formik.values)}
              />
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default MultiStepForm;

export const FormStep = ({ stepName = "", children }) => {
  const formik = useFormikContext();

  if (typeof children === "function") {
    return children(formik);
  }

  return children;
};
