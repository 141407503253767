import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import apiRequest from "../lib/ApiRequest";

const useLikeUnlike = () => {
    const queryClient = useQueryClient();

    const { mutate: likeUnlikePost, isPending: isLiking } = useMutation({
        mutationFn: async (postId) => {
            const res = await apiRequest.post(`/posts/like/${postId}`);
            return res.data;
        },
        onSuccess: (updatedLikes, postId) => {
            queryClient.setQueryData(["posts"], (oldData) => {
                if (!oldData) return [];
                return oldData.map((p) => (p._id === postId ? { ...p, likes: updatedLikes } : p));
            });
            if (queryClient.getQueryData(["userPost", postId])) {
                queryClient.invalidateQueries(["userPost", postId]);
            }
            toast.success("Post liked/unliked successfully!");
        },
        onError: (error) => {
            toast.error(error.response?.data?.message || "Something went wrong!");
        },
    });

    return { likeUnlikePost, isLiking };
};

export default useLikeUnlike;
