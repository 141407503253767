import React, { useEffect, useRef, useState } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa6";
import { IoIosMore } from "react-icons/io";
import { FaRegComment } from "react-icons/fa";
import { formatRelativeTime } from "../../utils/timeFormatter";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import apiRequest from "../../lib/ApiRequest";
import LoadingSpinner from "../LoadingSpinner";
import { VscAccount } from "react-icons/vsc";
import useLikeUnlike from "../../hooks/useLikeUnlikePost";
import SingelPostComment from "./components/SingelPostComment";

const Feed = ({ post }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const menuRef = useRef(null);
    const queryClient = useQueryClient();

    const { data: authUser } = useQuery({ queryKey: ["authUser"] });
    const { likeUnlikePost, isLiking } = useLikeUnlike();


    const postOwner = post?.user;
    const isLiked = post?.likes?.includes(authUser?._id);
    const isMyProfile = authUser?._id === post?.user?._id;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const { mutate: deletePost } = useMutation({
        mutationFn: async () => {
            await apiRequest.delete(`/posts/${post._id}`);
        },
        onSuccess: () => {
            toast.success("Post deleted successfully!");
            queryClient.invalidateQueries({ queryKey: ["posts"] });
        },
        onError: (error) => {
            toast.error(error.response?.data?.error || "Failed to delete post!");
        },
    });

    const displayName = postOwner?.isSupplier
        ? <span className="font-bold text-mainColor-500">{postOwner?.companyData?.[0]?.company}</span>
        : <span>{`${postOwner?.firstName} ${postOwner?.lastName}`}</span>;

    return (
        <div className="bg-background text-gray-300 flex flex-col p-4 my-4 gap-4 rounded-lg shadow-md border border-mainColor-500">
            {/* User Info */}
            <div className="relative flex items-center justify-between border-b border-mainColor pb-3">
                <Link to={`/profile/${postOwner?._id}`} className="flex items-center gap-4">
                    {postOwner?.profileImg ? (
                        <img
                            src={postOwner.profileImg}
                            alt="User Avatar"
                            className="w-10 h-10 rounded-full border-2 border-mainColor"
                        />
                    ) : (
                        <VscAccount className="text-2xl text-mainColor-500" />
                    )}
                    <div>
                        <h3 className="text-mainColor font-semibold">{displayName}</h3>
                        <span className="text-sm text-gray-400">{formatRelativeTime(post?.createdAt)}</span>
                    </div>
                </Link>
                {isMyProfile && (
                    <IoIosMore className="text-2xl text-mainColor cursor-pointer" onClick={() => setIsOpen(!isOpen)} />
                )}
                {isOpen && (
                    <div ref={menuRef} className="absolute right-0 mt-2 w-64 bg-background border border-mainColor-500 shadow-lg rounded-lg p-2 z-50">
                        <button className="w-full text-left p-2 hover:bg-gray-100 flex items-center gap-2" onClick={deletePost}>
                            ❌ Zmazať príspevok
                        </button>
                    </div>
                )}
            </div>

            {/* Image */}
            {post?.img && <img src={post.img} alt="Post content" className="w-full rounded-lg" />}
            {/* Post Content */}
            <div className="mt-2 text-sm leading-relaxed">
                {post?.text ? (
                    <p>
                        <span className="font-semibold text-mainColor">{displayName} </span>
                        {isExpanded ? post?.text : `${post?.text?.slice(0, 100)} ...`}
                        {post?.text?.length > 100 && (
                            <span className="text-orange-500 cursor-pointer ml-2 hover:underline" onClick={() => setIsExpanded(!isExpanded)}>
                                {isExpanded ? " zobraziť menej" : " viac"}
                            </span>
                        )}
                    </p>
                ) : (
                    <p className="font-semibold text-mainColor">{displayName}</p>
                )}
            </div>
            {/* Actions */}
            <div className="flex items-center justify-between gap-4 text-mainColor">
                <div className="flex gap-1 items-center group cursor-pointer" onClick={() => likeUnlikePost(post._id)}>
                    {isLiking && <LoadingSpinner size={20} color="#fb6c09" />}
                    {!isLiked && !isLiking && <FaRegHeart className="text-xl cursor-pointer hover:text-red-500" />}
                    {isLiked && !isLiking && <FaHeart className="text-xl cursor-pointer hover:text-red-500" />}
                    <div className="text-gray-400 text-md">{post?.likes?.length || 0} Likes</div>
                </div>
                <div className="flex gap-1 items-center group cursor-pointer" onClick={() => setIsModalOpen(true)}>
                    <FaRegComment className="text-xl cursor-pointer" />
                    <div className="text-gray-400 text-sm">{post?.comments?.length || 0} komentárov</div>
                </div>
                <div></div>
                {/* <RiShareForwardLine className="text-xl cursor-pointer" /> */}
            </div>

            {isModalOpen && <SingelPostComment post={post} displayName={displayName} onClose={() => setIsModalOpen(false)} />}

        </div>
    );
};

export default Feed;
