import React, { useState } from "react";
import Calendar from "react-calendar";
import { motion } from "framer-motion";
import apiRequest from "../../../lib/ApiRequest";
import { FaClock } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

// Lokalizovaný dátum
const formatDate = (date) => {
  const days = ["Nedeľa", "Pondelok", "Utorok", "Streda", "Štvrtok", "Piatok", "Sobota"];
  return `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}.${date.getFullYear()} ${days[date.getDay()]}`;
};

const getFormattedDateForAPI = (date) => {
  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return localDate.toISOString().split("T")[0];
};

const CalendarDashboard = () => {
  const [date, setDate] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const selectedDate = getFormattedDateForAPI(date);
  const { profileId } = useParams();
  const navigate = useNavigate();
const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const currentProfileId = profileId || authUser?._id;
  const isMyProfile = !profileId || profileId === authUser?._id;



  // Získaj sumár rezervácií pre kalendár z backendu
  const { data: calendarSummary = [] } = useQuery({
    queryKey: ["calendarSummary", authUser?._id, activeStartDate],
    queryFn: async () => {
      const year = activeStartDate.getFullYear();
      const month = activeStartDate.getMonth();
      const res = await apiRequest.get(`/appointment/calendar-summary/${authUser._id}?year=${year}&month=${month}`);
      return res.data;
    },
    enabled: !!authUser,
  });


  
  // Funkcia: nájdi sumár pre konkrétny deň
  const getSummaryForDate = (date) => {
    const formatted = format(date, "yyyy-MM-dd");
    return calendarSummary.find((item) => item.date === formatted);
  };

  // Získaj moje rezervácie ako zákazník
  const { data: myAppointments = [] } = useQuery({
    queryKey: ["myAppointments", selectedDate, currentProfileId],
    queryFn: async () => {
      const res = await apiRequest.post(`/appointment/appointments/by-customer/${currentProfileId}`, {
        day: selectedDate,
      });
      return res.data.appointments || [];
    },
    enabled: !!authUser && !!currentProfileId,
  });

  // Získaj rezervácie, ktoré si ľudia urobili u mňa
  const { data: customerAppointments = [] } = useQuery({
    queryKey: ["appointmentsForMe", selectedDate],
    queryFn: async () => {
      const res = await apiRequest.post(`/appointment/appointments/by-supplier/${authUser._id}`, {
        day: selectedDate,
      });
      return res.data.appointments || [];
    },
    enabled: !!authUser,
  });

 
  const combinedAppointments = isMyProfile
  ? [...myAppointments, ...customerAppointments]
  : myAppointments;

  return (
    <div className="flex flex-col sm:flex-row bg-gray-100 min-h-screen p-4">
      {/* Calendar */}
      <div className="sm:w-2/5 w-full p-4 bg-white rounded-lg shadow-md">
        <h2 className="text-lg font-bold mb-4 text-center">📅 Kalendár rezervácií</h2>
        <Calendar
          onChange={setDate}
          value={date}
          onActiveStartDateChange={({ activeStartDate }) => setActiveStartDate(activeStartDate)}
          tileClassName={({ date }) =>
            format(date, "yyyy-MM-dd") === format(new Date(selectedDate), "yyyy-MM-dd")
              ? "font-semibold text-red rounded-full bg-blue-100"
              : ""
          }
          tileContent={({ date, view }) => {
            if (view !== "month" || !authUser) return null;
          
            const info = getSummaryForDate(date);
            if (!info || info.total === 0) return null;
          
            return (
              <div className="relative flex items-center justify-center">
                {/* 🔵 Modrý badge vľavo – rezervácie aktuálneho profilu ako zákazníka */}
                {info.mine > 0 && (
                  <div className="absolute top-0 left-0 flex items-center justify-center w-5 h-5 bg-blue-500 text-white text-xs font-bold rounded-full shadow-md">
                    {info.mine}
                  </div>
                )}
          
                {/* 🟠 Oranžový badge vpravo – iba ak pozerám svoj profil */}
                {isMyProfile && info.customer > 0 && (
                  <div className="absolute top-0 right-0 flex items-center justify-center w-5 h-5 bg-mainColor-500 text-white text-xs font-bold rounded-full shadow-md">
                    {info.customer}
                  </div>
                )}
              </div>
            );
          }}
        />
      </div>

      {/* Appointments */}
      <div className="sm:w-3/5 w-full p-4 mt-4 sm:mt-0 bg-white rounded-lg shadow-md overflow-y-auto">
        <h2 className="text-lg font-bold mb-4 text-center">
          🗓️ Všetky rezervácie pre: {formatDate(date)}
        </h2>

        {combinedAppointments.length === 0 ? (
          <p className="text-gray-500 text-center">❌ Žiadne rezervácie pre tento dátum</p>
        ) : (
          <div className="space-y-3">
            {combinedAppointments.map((appointment) => {
              const isMyReservation = appointment.reqId?._id === authUser._id;

              const getUserDisplayName = (user) =>
                user?.companyData?.[0]?.company ||
                `${user?.firstName || ""} ${user?.lastName || ""}`.trim() ||
                "Neznámy";

              return (
                <motion.div
                onClick={() => {
                  const userId = isMyReservation
                    ? appointment.supId?._id
                    : appointment.reqId?._id;
                  if (userId) {
                    navigate(`/profile/${userId}`);
                  }
                }}

                  key={appointment._id}
                  className={`p-4 rounded-lg shadow-md flex flex-col sm:flex-row justify-between items-start sm:items-center ${
                    isMyReservation ? "bg-blue-100 text-black" : "bg-mainColor-500 text-white"
                  }`}
                  whileHover={{ scale: 1.02 }}
                >
                  <div className="flex items-center space-x-3">
                    <FaClock className="text-lg" />
                    <div>
                      <span className="font-bold block">
                        {appointment.slotTime} - {appointment.slotEndTime}
                      </span>
                      <span className="text-sm block">
                        Služba: {appointment.serviceId?.name || "Neznáma služba"}
                      </span>
                      <span className="text-xs opacity-80">
                        {isMyReservation
                          ? `U: ${getUserDisplayName(appointment.supId)}`
                          : `Zákazník: ${getUserDisplayName(appointment.reqId)}`}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-col items-start sm:items-end mt-2 sm:mt-0">
                    <span
                      className={`px-3 py-1 rounded-md text-sm font-medium ${
                        appointment.status === "booked"
                          ? isMyReservation
                            ? "bg-blue-600 text-white"
                            : "bg-white text-black"
                          : "bg-gray-200 text-gray-600"
                      }`}
                    >
                      {appointment.status}
                    </span>
                    <span className="text-sm font-bold mt-1">💰 {appointment.serviceId?.price}€</span>
                  </div>
                </motion.div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarDashboard;
