import { useQuery, useQueryClient } from "@tanstack/react-query";
import apiRequest from "../lib/ApiRequest";

const fetchDistricts = async (regionRef) => {
  const response = await apiRequest.get(`/location/districts?regionRef=${regionRef}`);
  return response.data; // Ensure this is the correct structure
};

export function useDistricts(regionRef) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["districts", regionRef],
    queryFn: () => fetchDistricts(regionRef),
    enabled: !!regionRef, // Fetch only if a region is selected
    staleTime: 1000 * 60 * 10, // Cache data for 10 minutes
    onSuccess: (data) => {
      // ✅ Store fetched districts in cache
      queryClient.setQueryData(["districts", regionRef], data);
    },
    onError: (error) => {
      console.error("Error fetching districts:", error);
    },
  });
}
