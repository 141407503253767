import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const MessengerNavbarDialog = ({ toggleMessengerDialog }) => {
  return (
    <div className="absolute right-0 mt-2 w-64 bg-background text-white rounded-lg shadow-lg border border-mainColor-500 p-4 z-50">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold">Messenger</h2>
        <AiOutlineClose className="cursor-pointer text-xl" onClick={toggleMessengerDialog} />
      </div>
      <p className="text-sm text-gray-300 mt-2">
        Táto funkcia zatiaľ nie je dostupná.
      </p>
    </div>
  );
};

export default MessengerNavbarDialog;
