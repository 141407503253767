import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import ImageCropper from "./components/ImageCropper";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiRequest from "../../../lib/ApiRequest";
import toast from "react-hot-toast";

const ProfileImageDialog = ({ closeModal, selectedFile }) => {
  const [profileImg, setProfileImg] = useState("");
  const queryClient = useQueryClient();

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileImg(event.target.result); // Convert image to base64 for cropping
      };
      reader.readAsDataURL(selectedFile);
    }
  }, [selectedFile]);

  const { mutate: uploadImage } = useMutation({
    mutationFn: async (file) => {
      const formData = new FormData();
      formData.append("profileImg", file);

      const res = await apiRequest.post("/users/updateProfileImg", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.invalidateQueries({ queryKey: ["user"] });
      queryClient.invalidateQueries({ queryKey: ["authUser"] });
      closeModal();
    },
    onError: (error) => {
      const message = error.response?.data?.message || "Something went wrong!";
      toast.error(message);
    },
  });

  const handleSave = async (croppedImage) => {
    const byteString = atob(croppedImage.split(",")[1]);
    const mimeString = croppedImage.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: mimeString });
    const file = new File([blob], "profile.jpg", { type: mimeString });

    uploadImage(file);
  };

  return (
    <div className="relative z-10" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-black bg-opacity-70 backdrop-blur-sm flex items-center justify-center z-50">
        <div className="relative bg-background rounded-xl p-8 w-11/12 max-w-lg shadow-lg animate-fadeIn">
          <div className="flex justify-center">
            <button
              type="button"
              className="absolute top-4 right-4 text-gray-600 hover:text-red-500 transition"
              onClick={closeModal}
            >
              <IoClose size={24} />
            </button>

            <ImageCropper
              image={profileImg}
              onCropDone={handleSave} // Save directly after cropping
              closeModal={closeModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileImageDialog;
