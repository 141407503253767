import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import apiRequest from '../../../lib/ApiRequest';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { BsPencil, BsTrash } from 'react-icons/bs';

const AddServices = () => {
  const { profileId } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    duration: "",
    description: "",
    allDay: false,
    priceNegotiable: false,
  });

  const queryClient = useQueryClient();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => {
      let updatedForm = { ...prev, [name]: type === "checkbox" ? checked : value };
      if (name === "allDay" && checked) {
        updatedForm.duration = 1;
      }
      if (name === "priceNegotiable" && checked) {
        updatedForm.price = "Dohodou";
      }
      return updatedForm;
    });
  };
  const { data: userServices = [] } = useQuery({
    queryKey: ["userServices", profileId],
    queryFn: async () => {
      const res = await apiRequest.get(`/services/${profileId}`);
      return res.data || [];
    },
    enabled: !!profileId, // Zavolá sa len ak existuje profileId
  });

  const { mutate: createService, isLoading } = useMutation({
    mutationFn: async (data) => {
      try {
        const res = await apiRequest.post("/services/create", data);
        return res.data;
      } catch (error) {
        throw error.response?.data || error.message || error;
      }
    },
    onSuccess: () => {
      toast.success("Služba bola úspešne vytvorená!");
      queryClient.invalidateQueries({ queryKey: ["userServices"], profileId });
    },
    onError: (error) => {
      toast.error(`Chyba: ${error.message || "Niečo sa pokazilo"}`);
    },
  });

  const { mutate: deleteService } = useMutation({
    mutationFn: async (serviceId) => {
      await apiRequest.delete(`/services/${serviceId}`);
    },
    onSuccess: () => {
      toast.success("Služba bola odstránená!");
      queryClient.invalidateQueries({ queryKey: ["userServices"], profileId });
    },
    onError: () => {
      toast.error("Nepodarilo sa odstrániť službu.");
    },
  });

  const { mutate: updateService } = useMutation({
    mutationFn: async ({ serviceId, updatedData }) => {
      const res = await apiRequest.put(`/services/${serviceId}`, updatedData);
      return res.data;
    },
    onSuccess: () => {
      toast.success("Služba bola úspešne aktualizovaná!");
      queryClient.invalidateQueries({ queryKey: ["userServices"], profileId });
      setFormData({ name: "", price: "", duration: "", description: "", allDay: false, priceNegotiable: false });
    },
    onError: () => {
      toast.error("Nepodarilo sa aktualizovať službu.");
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name || (!formData.price && !formData.priceNegotiable) || (!formData.allDay && !formData.duration)) {
      alert("Vyplňte všetky povinné polia.");
      return;
    }
    if (formData._id) {
      updateService({ serviceId: formData._id, updatedData: formData });
    } else {
      createService(formData);
    }
  };

  const handleDelete = (serviceId) => {
    deleteService(serviceId);
  };

  const handleEdit = (service) => {
    setFormData(service);
  };


  return (

    <div className="bg-white w-full rounded-lg shadow-lg p-6">
      {/* Progress bar */}
      <div className="flex items-center justify-between mb-6 space-x-4">
        <p className="text-lg font-bold text-mainColor-500">Nastavenie služieb</p>
        <p className="text-sm text-gray-600">2 / 3</p>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
        <div className="bg-mainColor-500 h-2 rounded-full w-2/3"></div>
      </div>

      {/* Address Section */}
      <div className="bg-mainColor-100 p-4 rounded-lg border border-mainColor-300 mb-6">
        <p className="text-sm text-mainColor-700 font-medium">
        Zadaj základné údaje o svojej službe, ktorú ponúkaš svojim zákazníkom.
        </p>

      </div>

      <div className="overflow-x-auto">
      <table className="w-full border-collapse border border-gray-300 text-sm">
          <thead className="bg-mainColor-500 text-white">
            <tr>
              <th className="p-3 border border-gray-300">Služba</th>
              <th className="p-3 border border-gray-300">Cena</th>
              <th className="p-3 border border-gray-300">Dĺžka trvania</th>
              <th className="p-3 border border-gray-300">Popis</th>
              <th className="p-3 border border-gray-300"></th>
            </tr>
          </thead>
          <tbody>
            {userServices.length > 0 ? (
              userServices.map((service, index) => (
                <tr key={index} className="text-center">
                  <td className="p-3 border border-gray-300">{service.name}</td>
                  <td className="p-3 border border-gray-300">{service.price === "Dohodou" ? "Dohodou" : `${service.price}€`}</td>
                  <td className="p-3 border border-gray-300">{service.allDay ? "Celodenná služba" : `${service.duration} min`}</td>
                  <td className="p-3 border border-gray-300">{service.description}</td>
                  <td className="p-3 border border-gray-300 ">
                    <div className='flex justify-around'>
                      <BsTrash className='text-mainColor-500 cursor-pointer' onClick={() => handleDelete(service._id)} />
                      <BsPencil className='text-mainColor-500 cursor-pointer' onClick={() => handleEdit(service)} />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="p-3 border border-gray-300 text-center text-gray-500">
                  Žiadne služby nie sú dostupné
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Form */}
      <form onSubmit={handleSubmit}>
        <div className="mb-6">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <label className="block text-sm font-medium text-primaryText">Názov služby *</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="mt-1 block w-full py-2 border border-mainColor-300 rounded-md shadow-sm"
                placeholder="Názov služby"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Dĺžka trvania *</label>
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  name="duration"
                  value={formData.duration}
                  onChange={handleInputChange}
                  className="mt-1 block w-full py-2 border border-mainColor-300 rounded-md shadow-sm"
                  placeholder="Dĺžka trvania (min)"
                  disabled={formData.allDay}
                />
                <input
                  type="checkbox"
                  name="allDay"
                  checked={formData.allDay}
                  onChange={handleInputChange}
                />
                <label className="text-sm">Celodenná služba</label>
              </div>
            </div>



            <div>
              <label className="block text-sm font-medium text-gray-700">Cena *</label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                className="mt-1 block w-full py-2 border border-mainColor-300 rounded-md shadow-sm"
                placeholder="Zadaj cenu"
                disabled={formData.priceNegotiable}
              />
            </div>
          </div>
          {formData.allDay && (
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="priceNegotiable"
                checked={formData.priceNegotiable}
                onChange={handleInputChange}
              />
              <label className="text-sm">Cena dohodou</label>
            </div>
          )}

          <div className="grid grid-cols-1 gap-4 mt-4">
            <label className="block text-sm font-medium text-gray-700">Popis služby *</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="mt-1 block w-full py-2 border border-mainColor-300 rounded-md shadow-sm"
              placeholder="Popis služby"
            />
          </div>
        </div>

        <div className="flex justify-end space-x-2">
          <button
            type="submit"
            disabled={isLoading}
            className="bg-green-500 text-white font-bold py-2 px-6 rounded-lg hover:bg-green-600"
          >
            {formData._id ? "Aktualizovať" : "Uložiť"}
          </button>
        </div>
      </form>
    </div>

  );
};

export default AddServices;
