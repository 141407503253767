import React, { useState, useRef, useEffect } from 'react';
import { GoPencil } from 'react-icons/go';

const EditableInput = ({ 
  label, 
  value, 
  onSave, 
  usefull, 
  IsMyProfile, // New prop to control editability
  ...props 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value || ""); 
  const inputRef = useRef(null);

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  const handleBlur = () => {
    if (value !== inputValue && IsMyProfile) {
      onSave(inputValue); // Save only if it's the user's profile
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (value !== inputValue && IsMyProfile) {
        onSave(inputValue);
      }
      setIsEditing(false);
    }
  };

  return (
    <div className="relative flex items-center space-x-2 group">
      <strong className={`${usefull ? "text-mainColor-500" : "text-primaryText"}`}>
        {label}:
      </strong>
      {isEditing && IsMyProfile ? (
        <input
          {...props}
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
          className="border-b border-gray-400 focus:outline-none focus:border-blue-500"
        />
      ) : (
        <span className="text-mainText">
          {inputValue || "N/A"}
        </span>
      )}
      
    
      {IsMyProfile && (
                <button onClick={() => setIsEditing(true)} className="ml-2">
                  <GoPencil size={16} className="text-mainColor"/>
                </button>
              )}
    </div>
  );
};

export default EditableInput;
