import React, { useState } from 'react';
import { GrSearch } from "react-icons/gr";

const SearchBar = () => {
  const [location, setLocation] = useState('');
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [profession, setProfession] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    console.log({ location, checkIn, checkOut, profession });
  };

  return (
    <form 
      onSubmit={handleSearch} 
      className="max-w-md md:max-w-4xl mx-auto p-4 bg-white shadow-lg rounded-lg flex flex-col space-y-3 md:flex-row md:space-y-0 md:space-x-2"
    >
      <div className="w-full">
        <label htmlFor="location" className="block text-sm font-medium text-gray-700">Kde</label>
        <input
          id="location"
          name="location"
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="Zadaj okres alebo kraj"
          className="mt-1 block w-full p-2 border rounded-md focus:ring-mainColor-500 focus:border-mainColor-500"
        />
      </div>

      <div className="w-full">
        <label htmlFor="checkIn" className="block text-sm font-medium text-gray-700">Odkedy</label>
        <input
          id="checkIn"
          name="checkIn"
          type="date"
          value={checkIn}
          onChange={(e) => setCheckIn(e.target.value)}
          className="mt-1 block w-full p-2 border rounded-md focus:ring-mainColor-500 focus:border-mainColor-500"
        />
      </div>

      <div className="w-full">
        <label htmlFor="checkOut" className="block text-sm font-medium text-gray-700">Dokedy</label>
        <input
          id="checkOut"
          name="checkOut"
          type="date"
          value={checkOut}
          onChange={(e) => setCheckOut(e.target.value)}
          className="mt-1 block w-full p-2 border rounded-md focus:ring-mainColor-500 focus:border-mainColor-500"
        />
      </div>

      <div className="w-full">
        <label htmlFor="profession" className="block text-sm font-medium text-gray-700">Koho potrebuješ?</label>
        <input
          id="profession"
          name="profession"
          type="text"
          value={profession}
          onChange={(e) => setProfession(e.target.value)}
          placeholder="Profesia"
          className="mt-1 block w-full p-2 border rounded-md focus:ring-mainColor-500 focus:border-mainColor-500"
        />
      </div>

      <button
        type="submit"
        className="w-full md:w-auto flex justify-center items-center py-2 px-4 border rounded-full shadow-sm bg-mainColor hover:bg-mainColor-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mainColor-500"
      >
        <GrSearch size={20} />
      </button>
    </form>
  );
};

export default SearchBar;