import * as yup from "yup";

export const validationSchema = yup.object({
  isSupplier: yup.bool(),
  email: yup
    .string()
    .email("Zlý format emailu") // "Invalid email format" error message
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Email musí byť správny formát"
    ) // General email pattern
    .required("Zadaj svoj email"), // "Enter your email" error message
    phoneNumber: yup
    .string()
    .required("Zadaj telefónne číslo")
    .matches(
      /^((\+421|00421)?9\d{8}|(\+420|00420)?[6-7]\d{8})$/,
      "Neplatné telefónne číslo"
    ),
  password: yup
    .string()
    .required("Zadaj heslo")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Musí obsahovať minimálne 8 znakov, jedno veľké písmeno, jedno malé písmeno, jedno číslo a jeden špeciálny znak"
    ),
  re_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Hesla sa musia zhodovať")
    .required("Zopakuj heslo"),
});

export const validationSchemaIsSuplierStepTwo = yup.object({
  ico: yup.string().required("IČO je povinné"),
  companyName: yup.string().required("Názov firmy je povinný"),
  firstName: yup
    .string()
    .required("Zadaj svoje meno")
    .matches(
      /^[A-Za-zÁ-žá-ž\s]+$/,
      "Meno môže obsahovať iba písmená a medzery"
    ),
  lastName: yup
    .string()
    .required("Zadaj svoje priezvisko")
    .matches(
      /^[A-Za-zÁ-žá-ž\s]+$/,
      "Meno môže obsahovať iba písmená a medzery"
    ),
  dic: yup.string(),
  icdph: yup.string(),
  street: yup.string().required("Adresa je povinná"),
  city: yup.string().required("Mesto je povinné"),
  postalCode: yup.string().required("PSČ je povinné"),
  country: yup.string().required("Krajina je povinné"),
});

export const validationSchemaIsNoSuplierStepTwo = yup.object({
  firstName: yup
    .string()
    .required("Zadaj svoje meno")
    .matches(
      /^[A-Za-zÁ-žá-ž\s]+$/,
      "Meno môže obsahovať iba písmená a medzery"
    ),
  lastName: yup
    .string()
    .required("Zadaj svoje priezvisko")
    .matches(
      /^[A-Za-zÁ-žá-ž\s]+$/,
      "Meno môže obsahovať iba písmená a medzery"
    ),
});

export const supplierValidationSchema = yup.object({
  agreement: yup
    .bool()
    .oneOf([true], "Pre registráciu musíš súhlasiť s podmienkami!")
    .required("Musíte súhlasiť s našimi podmienkami!"),
  firstName: yup
    .string()
    .required("Zadaj svoje meno")
    .matches(
      /^[A-Za-zÁ-žá-ž\s]+$/,
      "Meno môže obsahovať iba písmená a medzery"
    ),
  lastName: yup
    .string()
    .required("Zadaj svoje priezvisko")
    .matches(
      /^[A-Za-zÁ-žá-ž\s]+$/,
      "Meno môže obsahovať iba písmená a medzery"
    ),
  companyName: yup.string().required("Názov firmy je povinný"),
  ico: yup.string().required("IČO je povinné"),
  dic: yup.string().required("DIČ je povinné"),
  icdph: yup.string(),
  formatedAdress: yup.string().required("Adresa je povinná"),
  city: yup.string().required("Mesto je povinné"),
  psc: yup.string().required("PSČ je povinné"),
});

export const customerValidationSchema = yup.object({
  isSupplier: yup.bool(),

  email: yup
    .string()
    .email("Zlý format emailu") // "Invalid email format" error message
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Zadaj Email v správnom formáte"
    ) // General email pattern
    .required("Zadaj svoj email"), // "Enter your email" error message

  phoneNumber: yup
    .string()
    .required("Zadaj telefónne číslo") // "Enter your phone number" error message
    .matches(
      /^(\+421[0-9]{3}[0-9]{3}[0-9]{3}|09[0-9]{2}[0-9]{3}[0-9]{3})$/,
      "Neplatné telefónne číslo"
    ),
  password: yup
    .string()
    .required("Zadaj heslo")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Musí obsahovať minimálne 8 znakov, jedno veľké písmeno, jedno malé písmeno, jedno číslo a jeden špeciálny znak"
    ),
  re_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Hesla sa musia zhodovať")
    .required("Zopakuj heslo"),
  agreement: yup
    .bool()
    .oneOf([true], "Pre registráciu musíš súhlasiť s podmienkami!")
    .required("Musíte súhlasiť s našimi podmienkami!"),
  firstName: yup
    .string()
    .required("Zadaj svoje meno")
    .matches(
      /^[A-Za-zÁ-žá-ž\s]+$/,
      "Meno môže obsahovať iba písmená a medzery"
    ),
  lastName: yup
    .string()
    .required("Zadaj svoje priezvisko")
    .matches(
      /^[A-Za-zÁ-žá-ž\s]+$/,
      "Meno môže obsahovať iba písmená a medzery"
    ),
});

export const LoginValidationSchema = yup.object({
  email: yup
    .string()
    .email("Zlý format emailu") // "Invalid email format" error message
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Zadaj Email v správnom formáte"
    ) // General email pattern
    .required("Zadaj svoj email"), // "Enter your email" error message

  password: yup.string().required("Zadaj heslo"),
});


export const PasswordResetValidationSchema = yup.object({
  password: yup
  .string()
  .required("Zadaj heslo")
  .matches(
    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    "Musí obsahovať minimálne 8 znakov, jedno veľké písmeno, jedno malé písmeno, jedno číslo a jeden špeciálny znak"
  ),
re_password: yup
  .string()
  .oneOf([yup.ref("password"), null], "Hesla sa musia zhodovať")
  .required("Zopakuj heslo"),
});

