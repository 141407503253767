import React from "react";
import "./stepper.css";
import { TiTick } from "react-icons/ti";
import { MdPerson } from "react-icons/md";
import { LuIdCard } from "react-icons/lu";
import { CiCreditCard2 } from "react-icons/ci";

const iconMap = {
  0: <MdPerson size={20} />,
  1: <LuIdCard size={20} />,
  2: <CiCreditCard2 size={20} />,
};

const Stepper = ({ stepNumber, steps }) => {
  const currentStep = stepNumber + 1;

  return (
    <div className="w-full max-w-screen-sm mx-auto ">
      <div className="flex flex-row sm:flex-row justify-center sm:gap-4 items-center">
        {steps?.map((step, i) => (
          <div
            key={i}
            className={`step-item ${currentStep === i + 1 ? "active" : ""} ${
              i + 1 < currentStep ? "complete" : ""
            }`}
          >
            <div className="step">
              {i + 1 < currentStep ? (
                <TiTick size={20} />
              ) : (
                <>
                  <span className="block sm:hidden">{iconMap[i]}</span>
                  <span className="hidden sm:block">{i + 1}</span>
                </>
              )}
            </div>
            <p className="text-[10px] sm:text-sm text-gray-500 text-center max-w-[80px] break-words leading-tight">
              {step.props.stepName}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
