import { useCallback, useEffect, useState } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import apiRequest from "../../lib/ApiRequest";
import { FaRegHeart, FaRegComment } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import AddPost from "../../components/AddPost/AddPost";
import Button from "../../components/inputs/Button";
import Calendar from "../../components/Calendar/Calendar";
import Review from "../../components/Review/Review";
import CustomerCalendar from "../../components/Calendar/components/CustomerCalendar";
import ViewPostModalMobile from "./components/ViewPostModalMobile";
import ViewPostModalDesktop from "./components/ViewPostModalDesktop";
import { BiImage } from 'react-icons/bi';
import { RxVideo } from "react-icons/rx";

const ProfilePage = () => {
  const { profileId } = useParams();
  const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const { data: user } = useQuery({ queryKey: ["user"] });
  const { selectedTab } = useOutletContext();
  const [viewPostModalOpen, setViewPostModalOpen] = useState(false);
  const [selectedPostIndex, setSelectedPostIndex] = useState(0);
  const [commentText, setCommentText] = useState("");
  const [addPostModalOpen, setAddPostModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const { data: userPosts = [], refetch } = useQuery({
    queryKey: ["userPost", profileId],
    queryFn: async () => {
      try {
        const res = await apiRequest.get(`posts/user/${profileId}`);
        if (res.status !== 200) throw new Error("Failed to fetch user posts.");
        return res.data || [];
      } catch (error) {
        console.error("Error fetching posts:", error);
        return [];
      }
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    refetch();
  }, [profileId, refetch]);

  const nextPost = useCallback(() => {
    setSelectedPostIndex((prevIndex) => (prevIndex < userPosts.length - 1 ? prevIndex + 1 : prevIndex));
  }, [userPosts.length]);

  const prevPost = useCallback(() => {
    setSelectedPostIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  }, []);

  const handleKeyDown = useCallback((event) => {
    if (event.key === "ArrowRight") nextPost();
    if (event.key === "ArrowLeft") prevPost();
    if (event.key === "Escape") closeViewPostModal();
  }, [nextPost, prevPost]);

  useEffect(() => {
    refetch();
  }, [profileId, refetch]);

  useEffect(() => {
    if (viewPostModalOpen) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [viewPostModalOpen, handleKeyDown]);

  const openAddPostModal = () => setAddPostModalOpen(true);
  const closeAddPostModal = () => setAddPostModalOpen(false);

  const openViewPostModal = (index) => {
    setSelectedPostIndex(index);
    setViewPostModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeViewPostModal = () => {
    setViewPostModalOpen(false);
    document.body.style.overflow = "auto";
  };

 const IsMyProfile = authUser?._id === profileId;

  return (
    <div className="flex flex-col gap-2 items-center">
      {selectedTab === "posts" && (
        <>
          {IsMyProfile && (
            <div className=" w-full lg:w-1/3">            
              <Button label="Pridať prispevok" icon={IoMdAddCircleOutline} onClick={openAddPostModal} />
            </div>
          )}

          {/* Posts Grid */}
          <div className="grid grid-cols-3 gap-4 mt-4 justify-center items-center">
            {userPosts.length > 0 ? (
              userPosts.map((post, index) => (
                <div
                  key={post._id}
                  className="relative bg-white rounded-lg overflow-hidden shadow-md cursor-pointer transition-transform transform hover:scale-105"
                  onClick={() => openViewPostModal(index)} // Opens post modal
                >
                  <img
                    src={post?.img || "https://via.placeholder.com/300"}
                    alt={post?._id}
                    className="w-full h-full object-cover aspect-square rounded-lg"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 transition-opacity duration-300 flex items-center justify-center text-white text-2xl font-bold hover:opacity-100">
                    <span>
                      <FaRegHeart /> {post?.likes?.length || 0}
                    </span>
                    <span className="ml-4">
                      <FaRegComment /> {post?.comments?.length || 0}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-lg text-gray-500 mt-4">No posts available.</p>
            )}
          </div>
        </>
      )}

      {selectedTab === "calendar" && (
        <div className="display flex flex-col justify-center gap-6">
          {user?.isSupplier ? <Calendar user={user} /> : <CustomerCalendar />}
        </div>
      )}

      {selectedTab === "ratings" && <div><Review /></div>}

     {/* Add Post Modal */}
     {addPostModalOpen && (     
            <AddPost closeModal={closeAddPostModal} user={user} />
                )}


      {viewPostModalOpen && (
        isMobile ? (
          <ViewPostModalMobile
            userPosts={userPosts}
            selectedPostIndex={selectedPostIndex}
            setSelectedPostIndex={setSelectedPostIndex}
            closeViewPostModal={closeViewPostModal}
            commentText={commentText}
            setCommentText={setCommentText}
          />
        ) : (
          <ViewPostModalDesktop
            userPosts={userPosts}
            selectedPostIndex={selectedPostIndex}
            setSelectedPostIndex={setSelectedPostIndex}
            closeViewPostModal={closeViewPostModal}
            commentText={commentText}
            setCommentText={setCommentText}
          />
        )
      )}
    </div>
  );
};

export default ProfilePage;
