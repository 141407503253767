import { useQuery } from "@tanstack/react-query";
import apiRequest from "../lib/ApiRequest";

const fetchRegions = async (country) => {
  try {
    const response = await apiRequest.get(`/location/regions?country=${country}`);
    return response.data; // API should return an array of regions
  } catch (error) {
    console.error("Error fetching regions:", error.response?.data || error.message);
    throw new Error("Failed to load regions");
  }
};

export function useRegions(country) {
  return useQuery({
    queryKey: ["regions", country],
    queryFn: () => fetchRegions(country),
    enabled: !!country, // Fetch only if country is selected
    staleTime: 1000 * 60 * 10, // Cache data for 10 minutes
    onError: (error) => {
      console.error("React Query Error - Regions:", error.message);
    },
  });
}
