import { VscAccount } from "react-icons/vsc";
import { formatRelativeTime } from "../../../utils/timeFormatter";

const CommentList = ({ comments }) => {
    return (
        <div className="flex flex-col gap-3 md:max-h-80 overflow-auto">
            {comments.map((comment) => {
                const { user } = comment;

                // Determine the display name
                const displayName = user?.isSupplier
                    ? user.companyData?.[0].company // Use company name for suppliers
                    : `${user?.firstName} ${user?.lastName}`; // Otherwise, use first and last name

                return (
                    <div key={comment._id} className="flex gap-2 p-2 rounded-lg border border-mainColor-700 bg-background">
                        {/* Profile Image */}
                        <div className="flex-shrink-0">
                            {user?.profileImg ? (
                                <img
                                    src={user.profileImg}
                                    alt="Profile"
                                    className="w-8 h-8 rounded-full border border-mainColor-500"
                                />
                            ) : (
                                <VscAccount className="w-8 h-8 text-mainColor-500 rounded-full border border-mainColor-500" />
                            )}
                        </div>

                        {/* Comment Content */}
                        <div className="flex flex-col">
                            <div className="flex items-center gap-2">
                                <span className="font-bold text-mainColor">{displayName}</span>
                            </div>
                            <div className="text-sm text-primaryText">{comment.text}</div>

                            {/* Actions below the comment */}
                            <div className="flex items-center gap-4 text-mainColor-500 text-xs mt-1">
                                <span>{formatRelativeTime(comment.createdAt)}</span>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default CommentList;
