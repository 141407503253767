import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { AiOutlineClose } from "react-icons/ai";
import apiRequest from '../../../lib/ApiRequest';
import { FaHeart, FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { formatRelativeTime } from '../../../utils/timeFormatter';
import toast from 'react-hot-toast';

const InfoNavbarDialog = ({ toggleInfoDialog }) => {
    const queryClient = useQueryClient();

    const { data: notifications = [], isLoading, error } = useQuery({
        queryKey: ["notifications"],
        queryFn: async () => {
            try {
                const res = await apiRequest.get("/notifications/");
                if (res.status !== 200) {
                    throw new Error("Failed to fetch user notification.");
                }

                const data = res.data;
                if (!Array.isArray(data)) {
                    throw new Error("Invalid response format");
                }
                return data;

            } catch (error) {
                throw error;
            }
        },
    });

    const { mutate: deleteNotifications } = useMutation({
        mutationFn: async () => {
            try {
                const res = await apiRequest.delete("/notifications"); // Ensure correct API endpoint
                if (res.status !== 200) {
                    throw new Error("Failed to delete notifications.");
                }
                return res.data;
            } catch (error) {
                throw new Error(error.response?.data?.message || "Error deleting notifications.");
            }
        },
        onSuccess: () => {
            toast.success("Notifications deleted successfully");
            queryClient.invalidateQueries({ queryKey: ["notifications"] });
        },
        onError: (error) => {
            toast.error(error.message);
        }
    });

    return (
        <div className="absolute right-0 mt-2 w-80 lg:w-96 bg-background text-white rounded-lg shadow-lg border border-mainColor-500 p-4 z-50">
            <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold">Informácie</h2>
                <AiOutlineClose className="cursor-pointer text-xl" onClick={toggleInfoDialog} />
            </div>

            <button
                onClick={deleteNotifications}
                className="text-red-500 hover:underline cursor-pointer mt-2"
            >
                Delete all notifications
            </button>

            {isLoading && <p className="text-gray-500">Načítavam notifikácie...</p>}
            {error && <p className="text-red-500">Chyba pri načítaní notifikácií.</p>}

            {notifications.length === 0 && !isLoading && (
                <p className="text-gray-500">Žiadne nové notifikácie.</p>
            )}

            {notifications.map((notification) => {
                const fromUser = notification.from || {};
                const userName = fromUser.isSupplier
                    ? fromUser.companyData?.[0]?.company || "Neznáma firma"
                    : `${fromUser.firstName || "Neznámy"} ${fromUser.lastName || "Používateľ"}`;

                return (
                    <div className="border-b border-gray-700" key={notification._id}>
                        <div className="flex gap-2 p-4">
                            {/* Notification Type Icon */}
                            <div className="w-12 h-20 rounded-l-xl flex items-center justify-center text-xl font-bold text-white bg-mainColor-500">
                                {notification.type === "follow" && <FaUser className="w-4 h-4 text-primary" />}
                                {notification.type === "like" && <FaHeart className="w-4 h-4 text-primary" />}
                            </div>

                            {/* Notification Content */}
                            <Link to={`/profile/${fromUser._id || "#"}`}>
                                <div className="flex items-center gap-3">
                                    <img
                                        src={fromUser.profileImg || "/default-avatar.png"}
                                        alt="User Profile"
                                        className="w-8 h-8 rounded-full"
                                    />
                                    <div>
                                        <p className="font-semibold">{userName}</p>
                                        <p className="text-gray-500 text-sm">{formatRelativeTime(notification?.createdAt)}</p>
                                    </div>
                                </div>

                                <div className="flex gap-1">
                                    {/* <span className="font-bold">{userName}</span> */}
                                    {notification.type === "follow" ? "vás začal sledovať" : "páčilo sa mu váš príspevok"}
                                </div>
                            </Link>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default InfoNavbarDialog;
