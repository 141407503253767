import React, { useState } from 'react'
import { FaChevronLeft, FaHeart, FaRegCommentDots, FaRegHeart } from 'react-icons/fa';
import { useSwipeable } from "react-swipeable";
import LoadingSpinner from '../../../components/LoadingSpinner';
import useLikeUnlike from '../../../hooks/useLikeUnlikePost';
import { useQuery } from '@tanstack/react-query';
import SingelPostComment from '../../../components/feeds/components/SingelPostComment';


const ViewPostModalMobile = ({
  selectedPostIndex,
  setSelectedPostIndex,
  userPosts,
  closeViewPostModal,
}) => {
   const[isModalOpen,setIsModalOpen]=useState(false)
  const { likeUnlikePost, isLiking } = useLikeUnlike();
  const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const isLiked = userPosts[selectedPostIndex]?.likes?.includes(authUser?._id);
  const prevPost = () => {
    setSelectedPostIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const nextPost = () => {
    setSelectedPostIndex((prevIndex) => (prevIndex < userPosts.length - 1 ? prevIndex + 1 : prevIndex));
    
  };

  const swipeHandlers = useSwipeable({
    onSwipedDown: nextPost,
    onSwipedUp: prevPost,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  console.log(userPosts[selectedPostIndex]._id)

  return (
    <div className="fixed inset-0 bg-black/70 flex flex-col items-center justify-center z-50">
      <div {...swipeHandlers} className="relative w-full h-full bg-black rounded-lg overflow-hidden">

        {/* close navigation */}
        <div className="flex items-center p-4 border-b border-gray-700 relative">
          <div className="absolute left-4 text-white text-xl" onClick={closeViewPostModal} ><FaChevronLeft /></div>
          <div className="mx-auto text-white font-semibold">Príspevok</div>
        </div>

        {/* Header */}
        <div className="flex items-center p-4 border-b border-gray-700">
          <img
            src={userPosts[selectedPostIndex]?.user.profileImg}
            alt={userPosts[selectedPostIndex]?.user._id}
            className="w-10 h-10 rounded-full"
          />
          <div className="ml-3">
            <p className="text-lg font-bold">{userPosts[selectedPostIndex]?.user.firstName} {userPosts[selectedPostIndex]?.user.lastName}</p>
            {/* todo: imFollowing ?  pozriet ako to je na instagrame  */}
            <p className="text-xs text-gray-400">Sledované</p>
          </div>
          {/* todo: pozriet co je na instagrame  */}
          <div className="ml-auto text-gray-400">•••</div>
        </div>

        {/* Image */}
        <div>
          <img
            src={userPosts[selectedPostIndex]?.img}
            alt={userPosts[selectedPostIndex]?.text}
            className="w-full h-auto max-h-[70vh] object-contain"
          />
          {/* ImageActions */}
          <div className="flex justify-between items-center p-4 border-t border-gray-700">
            <div className='flex flex-row gap-4'>
            <div className="flex gap-1 items-center group cursor-pointer text-mainColor" onClick={() => likeUnlikePost(userPosts[selectedPostIndex]?._id)}>
              {isLiking && <LoadingSpinner size={20} color="#fb6c09" />}
              {!isLiked && !isLiking && <FaRegHeart className="text-xl cursor-pointer hover:text-red-500" />}
              {isLiked && !isLiking && <FaHeart className="text-xl cursor-pointer hover:text-red-500" />}

            </div>
            <FaRegCommentDots className="text-xl cursor-pointer text-mainColor"onClick={() => setIsModalOpen(true)} />
            </div>
            {/* placeholder pre dalsiu action Icon */}
          </div>
          {/* Likes info */}
          <div className="p-4 text-sm">
            <p className='text-md text-gray-400'>
              <strong>365</strong> a {365 - 1} ďalší to označili Páči sa mi to
            </p>

            <p className="text-lg font-bold text-mainColor-500">{userPosts[selectedPostIndex]?.user.firstName} {userPosts[selectedPostIndex]?.user.lastName}</p>
            <p className="text-lg ">{userPosts[selectedPostIndex]?.text}</p>

            <p className="text-lg mt-4" onClick={() => setIsModalOpen(true)}>Zobraziť všetky komentáre (11)</p>
            <p className="text-xs text-gray-400">Pred 2 dňami</p>

          </div>

        </div>
        {isModalOpen && <SingelPostComment post={userPosts[selectedPostIndex]}  onClose={() => setIsModalOpen(false)} />}

      </div>
    </div>
  )
}

export default ViewPostModalMobile