import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { GoPencil } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { useDistricts } from "../../hooks/useDistrict";
import { useRegions } from "../../hooks/useRegion";
import { useCountries } from "../../hooks/useCountries";

const BussinesArea = ({ label = "Business Area", onSave, IsMyProfile, usefull }) => {
  const [showDialog, setShowDialog] = useState(false);
  const { data: user } = useQuery({ queryKey: ["user"] });

  const [selectedCountry, setSelectedCountry] = useState("sk");
  const [offerMultiple, setOfferMultiple] = useState(false);
  const [offerNationwide, setOfferNationwide] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);

  // Fetch regions based on selected country
  const { data: regions = [], isLoading: loadingRegions } = useRegions(selectedCountry);

  // Fetch districts for multiple selected regions
  const { data: districts = [], isLoading: loadingDistricts } = useDistricts(
    offerMultiple ? selectedRegions : selectedRegions[0] || null
  );

  const { data: countries = [], isLoading, error } = useCountries();

  // Handle Region Selection
  const handleRegionChange = (e) => {
    const value = e.target.value;
    if (!offerMultiple) {
      setSelectedRegions([value]); // Single selection
      setSelectedDistricts([]); // Reset districts when changing region
    } else {
      // Multi-region selection
      setSelectedRegions((prevRegions) =>
        prevRegions.includes(value) ? prevRegions.filter((r) => r !== value) : [...prevRegions, value]
      );
      setSelectedDistricts([]); // Reset districts when changing regions
    }
  };


  const handleSave = () => {
    if (onSave) {
      // Find the country name based on the selected country code
      const countryData = countries.find((c) => c.code === selectedCountry);
      const countryName = countryData ? countryData.name : "Unknown Country";

      onSave({
        region: selectedRegions,
        district: offerMultiple ? [] : selectedDistricts,
        country: selectedCountry,
        countryName, // ✅ Send country name to backend
      });
    }
    setShowDialog(false);
  };

  return (
    <div>
      <div className="relative flex items-center space-x-2">
        <strong className={`${usefull ? "text-mainColor-500" : "text-primaryText"}`}>
          {label}:
        </strong>
        <span>
          {user?.operationArea && user.operationArea.length > 0 ? (
            user.operationArea.map((area, index) => (
              <div key={`${area.country}-${index}`}>
                {/* ✅ If districts exist, display them */}
                {area.district.length > 0 ? (
                  area.district.map((district, i) => (
                    <span key={`${area.country}-district-${district}`}>
                      {district}
                      {i < area.district.length - 1 ? ", " : ""}
                    </span>
                  ))
                ) : area.region.length > 0 ? (
                  /* ✅ If no districts, show region names */
                  area.region.map((regionObj, i) => (
                    <span key={`${regionObj.ref}-region`}>
                      {regionObj.name} {/* ✅ Extract name property */}
                      {i < area.region.length - 1 ? ", " : ""}
                    </span>
                  ))
                ) : (
                  /* ✅ If no regions or districts, show country */
                  <span className="text-primaryText">{area.countryName || "Unknown Country"}</span>
                )}
              </div>
            ))
          ) : (
            // ✅ Default fallback message
            <span className="text-primaryText">Vyberte krajinu, kraje alebo okresy</span>
          )}
        </span>



        {IsMyProfile && (
          <button onClick={() => setShowDialog(true)} className="ml-2">
            <GoPencil size={16} className="text-mainColor" />
          </button>
        )}
      </div>

      {showDialog && (
        <div className="fixed inset-0 bg-mainColor-700 bg-opacity-90 flex items-center justify-center z-50">
          <div className="flex h-full max-w-5xl w-full bg-background rounded-lg flex-col overflow-hidden">
          <div className="overflow-y-auto max-h-[90vh]">
            <div className="w-full bg-background flex justify-end p-2  ">
              <IoMdClose onClick={() => setShowDialog(false)} className="text-2xl cursor-pointer text-mainColor-500" />
            </div>
            <div className="flex justify-center text-lg font-bold mb-4 p-2 border-b border-mainColor-500 text-mainColor-500">{label}</div>
            <div className="p-6">
              <h3 className="text-lg font-bold mb-4">{label}</h3>

              <div className="bg-mainColor-100 p-4 rounded-lg border border-mainColor-300 mb-6">
                <p className="text-sm text-mainColor-500 font-medium">
                  Vyber krajinu, kraj alebo okres, kde ponúkaš služby, aby zákazníci vedeli o tvojej dostupnosti.
                  Ak poskytujete služby na viacerých miestach, môžete vybrať viacero krajov alebo okresov.
                </p>
              </div>
              {/* Nationwide Service Checkbox */}
              <div className="mb-4 flex items-center">
                <input
                  type="checkbox"
                  checked={offerNationwide}
                  onChange={(e) => {
                    setOfferNationwide(e.target.checked);
                    if (e.target.checked) {
                      setSelectedRegions([]);
                      setSelectedDistricts([]);
                    }
                  }}
                  className="mr-2"
                />
                <label className="text-sm font-medium text-mainColor-500">
                  Je tvojim cieľom ponúkať služby v celej krajine?
                </label>
              </div>

              {/* Select Country */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-mainColor-500">Krajina:</label>
                <select
                  value={selectedCountry}
                  onChange={(e) => {
                    setSelectedCountry(e.target.value);
                    setSelectedRegions([]);
                    setSelectedDistricts([]);
                    setOfferNationwide(false);
                  }}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                >
                  {isLoading ? (
                    <option>Loading countries...</option>
                  ) : error ? (
                    <option>Error loading countries</option>
                  ) : (
                    <>
                      <option value="">-- Vyber krajinu --</option>
                      {countries.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      ))}
                    </>
                  )}
                </select>
              </div>


              {/* Multi-region selection checkbox */}
              {!offerNationwide && (
                <div className="mb-4 flex items-center">
                  <input
                    type="checkbox"
                    checked={offerMultiple}
                    onChange={(e) => {
                      setOfferMultiple(e.target.checked);
                      if (!e.target.checked) {
                        setSelectedRegions([]);
                        setSelectedDistricts([]);
                      }
                    }}
                    className="mr-2"
                  />
                  <label className="text-sm font-medium text-mainColor-500">
                    Sú tvoje služby dostupné v rôznych krajoch?
                  </label>
                </div>
              )}

              {!offerNationwide && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-mainColor-500">Select Region(s):</label>
                  {offerMultiple ? (
                    <div className="mt-2">
                      {regions.map((region) => (
                        <div key={region.ref} className="flex items-center mb-1">
                          <input
                            type="checkbox"
                            id={`region-${region.ref}`}
                            value={region.ref}
                            checked={selectedRegions.includes(region.ref)}
                            onChange={(e) =>
                              setSelectedRegions((prev) =>
                                e.target.checked
                                  ? [...prev, region.ref]
                                  : prev.filter((r) => r !== region.ref)
                              )
                            }
                            className="mr-2"
                          />
                          <label htmlFor={`region-${region.ref}`}>{region.name}</label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <select
                      value={selectedRegions[0] || ""}
                      onChange={handleRegionChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                      disabled={loadingRegions}
                    >
                      <option value="">-- Vyberte kraj --</option>
                      {regions.map((region) => (
                        <option key={region.ref} value={region.ref}>{region.name}</option>
                      ))}
                    </select>
                  )}
                </div>
              )}


              {/* Select Districts as Checkboxes */}
              {!offerNationwide && !offerMultiple && selectedRegions.length > 0 && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Select District(s):</label>
                  <div className="mt-2">
                    {loadingDistricts ? (
                      <p className="text-sm text-gray-500">Loading districts...</p>
                    ) : (
                      districts.map((district) => (
                        <div key={district.ref} className="flex items-center mb-1">
                          <input
                            type="checkbox"
                            id={`district-${district.ref}`}
                            value={district.ref}
                            checked={selectedDistricts.includes(district.ref)}
                            onChange={(e) =>
                              setSelectedDistricts(
                                e.target.checked
                                  ? [...selectedDistricts, district.ref]
                                  : selectedDistricts.filter((ref) => ref !== district.ref)
                              )
                            }
                            className="mr-2"
                          />
                          <label htmlFor={`district-${district.ref}`}>{district.name}</label>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              )}

              <div className="flex justify-end space-x-2">
                <button onClick={() => setShowDialog(false)} className="px-4 py-2 bg-gray-200 rounded">
                  Cancel
                </button>
                <button onClick={handleSave} className="px-4 py-2 bg-blue-500 text-white rounded">
                  Save
                </button>
              </div>

            </div>

          </div>
        </div>
        </div>
      )}
    </div>
  );
};

export default BussinesArea;
