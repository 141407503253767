import { useField } from "formik";
import React from "react";

const RadioInput = ({
  label,
  icon: Icon,
  onIconClick,
  outline = false,
  small = false,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <div className="mb-4">
      <label className="block text-gray-700">
        <div className="relative mt-1">
          <input {...field} {...props} className="peer sr-only" />
          <p className="px-6 py-2  bg-white ring-2 ring-mainColor-500 rounded-lg text-sm shadow-sm peer-checked:text-white peer-checked:bg-mainColor-500 peer-checked:ring-mainColor-500">
            {" "}
            {label}
          </p>
        </div>
      </label>
      {meta.touched && meta.error && (
        <p className="mt-2 text-sm text-red-600">{meta.error}</p>
      )}
    </div>
  );
};

export default RadioInput;
