import { useState } from "react";
import Cropper from "react-easy-crop";
import { MdOutlineCropFree, MdZoomOutMap } from "react-icons/md";
import { Slider } from "@mui/material";
import { LuRectangleVertical, LuRectangleHorizontal, LuSquare } from "react-icons/lu";

const PostImageCropper = ({ image, onCropDone, onCropCancel }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);
    const [aspect, setAspect] = useState(1); // Default 1:1
    const [menuOpen, setMenuOpen] = useState(null);
    
    const toggleMenu = (menu) => {
        setMenuOpen(menuOpen === menu ? null : menu);
    };
    
    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
        onCropDone(croppedAreaPixels);
    };

    return (
        <div className="relative w-full h-[500px] flex flex-col items-center justify-center">
            {/* Cropper Section */}
            <Cropper
                image={image}
                aspect={aspect}
                crop={crop}
                zoom={zoom}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                showGrid={false}
                cropShape="rect"
                style={{
                    containerStyle: {
                        height: "100%",
                        backgroundColor: "#000",
                    }
                }}
            />
            
            {/* Controls Section */}
            <div className="absolute bottom-4 flex items-start gap-4 p-3 rounded-md shadow-lg">
                {/* Crop Ratio Button */}
                <button 
                    className="p-2 bg-black rounded-full opacity-75 hover:opacity-100 transition"
                    onClick={() => toggleMenu('aspect')}
                >
                    <MdOutlineCropFree size={20} className="text-mainColor-500" />
                </button>
                
                {/* Zoom Button */}
                <button 
                    className="p-2 bg-black rounded-full opacity-75 hover:opacity-100 transition"
                    onClick={() => toggleMenu('zoom')}
                >
                    <MdZoomOutMap size={20} className="text-mainColor-500" />
                </button>
            </div>
            
            {/* Aspect Ratio Menu */}
            {menuOpen === 'aspect' && (
               <div className="absolute bottom-16 left-4 bg-gray-900 p-3 rounded-md shadow-lg text-white flex flex-col gap-2">
               <button onClick={() => { setAspect(1); toggleMenu(null); }} className="p-2 bg-gray-800 rounded-md flex items-center gap-2">
                   <LuSquare className="w-5 h-5" />
                   1:1
               </button>
               <button onClick={() => { setAspect(4 / 5); toggleMenu(null); }} className="p-2 bg-gray-800 rounded-md flex items-center gap-2">
                   <LuRectangleVertical className="w-5 h-5" />
                   4:5
               </button>
               <button onClick={() => { setAspect(16 / 9); toggleMenu(null); }} className="p-2 bg-gray-800 rounded-md flex items-center gap-2">
                   <LuRectangleHorizontal className="w-5 h-5" />
                   16:9
               </button>
           </div>
            )}
            
            {/* Zoom Menu */}
            {menuOpen === 'zoom' && (
                <div className="absolute bottom-16 left-4 bg-gray-900 p-3 rounded-md shadow-lg text-white flex flex-col items-center justify-center w-[50%] lg:w-[15%]">
                    <Slider
                        color="orange"
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        onChange={(e, val) => setZoom(val)}
                        className="w-32 mt-2"
                    />
                </div>
            )}
        </div>
    );
};

export default PostImageCropper;
