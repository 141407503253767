import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { useSwipeable } from "react-swipeable";
import { useQuery } from "@tanstack/react-query";
import useLikeUnlike from "../../../hooks/useLikeUnlikePost";
import LoadingSpinner from "../../../components/LoadingSpinner";
import useCommentOnPost from "../../../hooks/useCommentPost";

const ViewPostModalDesktop = ({
    userPosts,
    selectedPostIndex,
    setSelectedPostIndex,
    closeViewPostModal,
}) => {
    const userPost = userPosts?.[selectedPostIndex] || {}; // Ensure userPost exists
    const user = userPost?.user || {}; // Ensure user object exists
    const [commentText, setCommentText] = useState("");
    const { likeUnlikePost, isLiking } = useLikeUnlike();
    const { commentPost, isCommenting } = useCommentOnPost();
    const { data: authUser } = useQuery({ queryKey: ["authUser"] });
    const isLiked = userPost?.likes?.includes(authUser?._id);

    // Post navigation handlers
    const prevPost = () => {
        setSelectedPostIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    };

    const nextPost = () => {
        setSelectedPostIndex((prevIndex) => (prevIndex < userPosts.length - 1 ? prevIndex + 1 : prevIndex));
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: nextPost,
        onSwipedRight: prevPost,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    // Display name for the user who made the post
    const displayName = user?.isSupplier ? (
        <span className="font-bold text-mainColor-500">
            {user?.companyData?.[0]?.company || "Unknown Company"}
        </span>
    ) : (
        <span>{`${user?.firstName || "Unknown"} ${user?.lastName || "User"}`}</span>
    );

    const commentDisplayName = (comment) =>
        comment?.user?.isSupplier ? (
            <span className="font-bold text-mainColor-500">
                {comment?.user?.companyData?.[0]?.company || "Unknown Company"}
            </span>
        ) : (
            <span>{`${comment?.user?.firstName || "Unknown"} ${comment?.user?.lastName || "User"}`}</span>
        );

    const handlePostComment = (e) => {
        e.preventDefault();
        if (!commentText.trim()) return;

        commentPost({ postId: userPost?._id, text: commentText, userId: user?._id });
        setCommentText(""); // Reset input after posting
    };

    return (
        <div className="fixed inset-0 bg-mainColor-700 bg-opacity-90 flex items-center justify-center z-50">
            <div {...swipeHandlers} className="relative flex items-center justify-center max-w-full w-full h-full lg:p-4">
                {selectedPostIndex > 0 && (
                    <div
                        className="hidden lg:block absolute top-[50%] left-4 transform -translate-y-1/2 text-xl sm:text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer z-10"
                        onClick={prevPost}
                    >
                        <BsChevronCompactLeft size={30} className="text-mainColor-500" />
                    </div>
                )}

                {/* Content */}
                <div className="flex h-full max-w-5xl w-full bg-black rounded-lg overflow-hidden flex-col" >
                    <div className="w-full bg-black flex justify-end p-2 border-b border-mainColor-500">
                        <IoMdClose onClick={closeViewPostModal} className="text-2xl cursor-pointer text-mainColor-500" />
                    </div>
                    <div className="w-full h-full flex flex-row">
                        <div className="lg:w-2/3 flex justify-center items-center border-r border-mainColor-600 p-4">
                            <img
                                src={userPost?.img || "default_image.jpg"}
                                alt={userPost?.text || "No description"}
                                className="max-h-[90vh] max-w-full rounded-lg"
                            />
                        </div>

                        <div className="w-1/3 bg-black text-white flex flex-col h-full">
                            {/* ✅ Fixed Top Section (User Info + Post Text) */}
                            <div className="flex flex-col p-2 max-h-[15%] flex-shrink-0">
                                <div className="flex items-start gap-4">
                                    <img
                                        src={user?.profileImg || "default_profile.png"}
                                        alt={user?._id || "Unknown User"}
                                        className="w-10 h-10 rounded-full"
                                    />
                                    <p className="text-lg font-bold">{displayName}</p>
                                </div>
                                <p className="text-lg mt-4">{userPost?.text || "No description available"}</p>
                            </div>

                            {/* ✅ Scrolling Middle Section (Comments) */}
                            <div className="mt-6 p-2 border-t border-mainColor-600 flex-1 overflow-y-auto max-h-[65%] px-2">
                                {userPost?.comments?.length > 0 ? (
                                    userPost.comments.map((comment, index) => (
                                        <div key={index} className="flex items-start gap-3 mb-2">
                                            <img
                                                src={comment?.user?.profileImg || "default_profile.png"}
                                                alt={comment?.user?.firstName || "Unknown"}
                                                className="w-8 h-8 rounded-full"
                                            />
                                            <div>
                                                <p className="text-sm font-bold">{commentDisplayName(comment)}</p>
                                                <p className="text-sm text-gray-400">{comment?.text || ""}</p>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-gray-400">No comments yet.</p>
                                )}
                            </div>

                            {/* ✅ Fixed Bottom Section (Likes + Comment Input) */}
                            <div className="border-t border-mainColor-600 p-2 flex-shrink-0">
                                <div className="mb-4">
                                    <div
                                        className="flex gap-1 items-center group cursor-pointer text-mainColor"
                                        onClick={() => likeUnlikePost(userPost?._id)}
                                    >
                                        {isLiking && <LoadingSpinner size={20} color="#fb6c09" />}
                                        {!isLiked && !isLiking && <FaRegHeart className="text-xl cursor-pointer hover:text-red-500" />}
                                        {isLiked && !isLiking && <FaHeart className="text-xl cursor-pointer hover:text-red-500" />}
                                    </div>
                                </div>
                                <p className="text-sm font-bold">{userPost?.likes?.length || 0} Páči sa mi to</p>
                                <p className="text-xs text-gray-400">Pred 2 dňami</p>

                                {/* ✅ Comment Input Section */}
                                <div className="mt-2 flex items-center border-t border-mainColor-600 pt-2">
                                    <input
                                        type="text"
                                        value={commentText}
                                        onChange={(e) => setCommentText(e.target.value)}
                                        placeholder="Pridať komentár..."
                                        className="w-full bg-transparent text-white p-2 focus:outline-none"
                                    />
                                    <button
                                        type="button"
                                        onClick={handlePostComment}
                                        disabled={isCommenting}
                                        className="text-mainColor-500 font-semibold"
                                    >
                                        Uverejniť
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {selectedPostIndex < userPosts.length - 1 && (
                    <div
                        className="absolute top-[50%] right-4 transform -translate-y-1/2 text-xl rounded-full p-2 bg-black/20 text-white cursor-pointer z-10"
                        onClick={nextPost}
                    >
                        <BsChevronCompactRight size={30} className="text-mainColor-500" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ViewPostModalDesktop;
