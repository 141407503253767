import React from 'react';
import OfferCard from './components/OfferCard';
import SearchBar from './components/SearchBar';

const Offers = () => {
  return (
    <>
    <div className=' pt-24 p-4 '>
        <SearchBar/>
        </div>
    <div className="min-h-screen bg-gray-100 p-24">
       
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6 auto-rows-fr">
        {Array.from({ length: 10 }).map((_, index) => (
          <div key={index} className="flex">
            <OfferCard
              image="https://source.unsplash.com/400x300/?abstract"
              profileImage="https://randomuser.me/api/portraits/men/32.jpg"
              companyName="TaProfi s.r.o."
              categories="Elektrikár, murár, barber"
              title="Constructive and destructive waves"
              description="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint."
              duration="2 hours 40 minutes"
              rating={2}
            />
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default Offers;
